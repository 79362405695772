import { PageFilter } from '@components/GenericOverview/types'
import {
	AccessRight,
	UserAccount,
	UserAccountEmail,
	UserAccountName,
	UserAccountStatus,
	UserAccountWork
} from '@graphql/graphql'
import { UserAccountOverviewStoryblok } from '@graphql/storyblokcomponents'

export interface UserAccountPageFilters {
	accessRight: PageFilter
	[key: string]: PageFilter
}

export type GoToDetailFnUserAccount = (n: Pick<UserAccount, 'id'>) => any

export type TableUserAccount = Pick<UserAccount, 'id'> & {
	name: Pick<UserAccountName, 'fullName'>
} & { accessRight: Pick<AccessRight, 'name'> } & {
	work?: Pick<UserAccountWork, 'company'>
}

export type UserAccountData = Pick<UserAccount, 'id'> & {
	name: Pick<UserAccountName, 'fullName'>
} & { accessRight: Pick<AccessRight, 'name'> } & {
	work?: Pick<UserAccountWork, 'company'>
} & { emails?: UserAccountEmail[] } & {
	status?: UserAccountStatus
}

export enum UserAccountColumnKeys {
	name = 'name',
	company = 'company',
	createdDateTime = 'createdDateTime',
	lastSignInDateTime = 'lastSignInDateTime',
	status = 'status'
}

export interface UserAccountColumnResolver {
	name: UserAccountColumnKeys
	company: UserAccountColumnKeys
	status: UserAccountColumnKeys
	createdDateTime: UserAccountColumnKeys
	lastSignInDateTime: UserAccountColumnKeys
}

export interface UserAccountOverviewProps {
	key: any
	blok: UserAccountOverviewStoryblok
}

export interface UserAccountFilterValueResolver {
	accessRight: (userAccountModel: UserAccountData) => string
	status: (userAccountModel: UserAccountData) => string
	[key: string]: (userAccountModel: UserAccountData) => string
}

export interface UserAccountSortingValueResolver {
	name: string
	company: string
	accessRight: string
	[key: string]: string
}

export interface UserAccountFilterNames {
	accessRight: string
	status: string
}
