import styled from 'styled-components'
import { theme } from '@damen/ui'

import { FormLabel as FormLabelElement } from '@components/UI/Forms/FormUI'

export const SelectAnchor = styled.object`
	display: block;
	width: 100%;
	height: 0;
`

export const RadioGroupError = styled.p`
	color: ${theme.colors.blue};
	font-weight: 500;
	width: 100%;
	margin: 8px 0 0 19px;
	line-height: 1;
	font-size: 14px;
`

export const FormLabel = styled(FormLabelElement)`
	display: block;
	margin-bottom: 0;
`

export const RadioDescription = styled.p`
	display: block;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: #808b9b;
	margin: -4px 0 10px 32px;
`

export const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: row;

	div[type='radio'] {
		display: inline-flex;
		margin-right: 20px;
		width: auto;
	}
`
