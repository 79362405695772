import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Formik } from 'formik'

import { MySettingsPreferencesStore } from '@contexts/MySettingsPreferences'
import { validationSchema } from '@contexts/MySettingsPreferences/Validation'

import FormFooter from '@components/UI/Forms/FormFooter'
import SidebarForm from '@components/SidebarForm'

import { NotificationTypes } from '@contexts/NotificationContext/types'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import { useNotificationContext } from '@contexts/NotificationContext'
import {
	GetMySettingsDocument,
	useEditMyUserAccountPreferencesMutation,
	useGetLanguagesQuery
} from '@graphql/graphql'
import { useRouter } from 'next/router'
import ErrorState from '@components/UI/Error'
import { FormTitle } from '@components/UI/Forms/FormHeader'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import { mapLanguages } from '@utils/mapLanguages'
import { FormFieldHorizontal, FormRow } from '@components/UI/Forms/FormUI'
import { OptionType } from '@damen/ui/lib/cjs/components/Form/Select'
import PreferencesFormFields from './PreferencesFormFields'
import { PreferencesProps, PreferencesRequestValues } from './types'

const Preferences: FC<PreferencesProps> = (props) => {
	const {
		labelCancel,
		labelSave,
		labelSaving,
		editPreferencesSuccess,
		editPreferencesError,
		onSubmitted,
		validation,
		myUserAccount,
		formErrorMessage
	} = props

	const { initialValues } = useContext(MySettingsPreferencesStore)
	const router = useRouter()
	const { locale } = router

	const [sending, setSending] = useState(false)
	const { sendNotification } = useNotificationContext()

	const [editMyUserAccountPreferences] =
		useEditMyUserAccountPreferencesMutation({
			refetchQueries: [GetMySettingsDocument]
		})

	const submitHandler = useCallback(
		async (values: PreferencesRequestValues) => {
			setSending(true)

			const language = mapLanguages(values)
			const caseCreatedNotification = values.caseCreated === 'true'
			const caseClosedNotification = values.caseClosed === 'true'
			const caseCommentCreatedNotification =
				values.caseCommentCreated === 'true'

			try {
				const request = await editMyUserAccountPreferences({
					variables: {
						request: {
							caseCreatedNotification,
							caseClosedNotification,
							caseCommentCreatedNotification,
							language
						}
					}
				})

				if (request.data) {
					sendNotification(
						NotificationTypes.SNACKBAR,
						{
							snackbarType: SnackbarNotificationTypes.SUCCESS,
							title:
								editPreferencesSuccess ??
								'[Preferences updated successfully!]'
						},
						{
							showAfterDelay: 100
						}
					)

					if (!locale.includes(values.language)) {
						router.replace(
							{
								pathname: `${values.language}/my-settings`
							},
							undefined,
							{ locale: values.language }
						)
					}

					onSubmitted()
				}
			} catch (requestError) {
				setSending(false)

				sendNotification(
					NotificationTypes.SNACKBAR,
					{
						snackbarType: SnackbarNotificationTypes.ERROR,
						title:
							editPreferencesError ??
							'[There was an error. Please try again later]'
					},
					{
						showAfterDelay: 0
					}
				)

				onSubmitted()
			}
		},
		[
			editMyUserAccountPreferences,
			editPreferencesError,
			editPreferencesSuccess,
			onSubmitted,
			sendNotification,
			locale,
			router
		]
	)
	const {
		data: languagesData,
		loading: languagesLoading,
		error: languagesError
	} = useGetLanguagesQuery({
		variables: {
			language: locale
		}
	})

	const languages: OptionType[] = useMemo(
		() =>
			languagesData?.languages?.map((language) => {
				return { label: language.name, value: language.id }
			}),
		[languagesData]
	)

	const preferences = myUserAccount?.notificationSettings

	const values = useMemo(() => {
		return {
			...initialValues,
			language: preferences?.preferredLanguage?.id,
			caseCreated: preferences?.createCase.toString(),
			caseClosed: preferences?.sendCaseClosureEmail.toString(),
			caseCommentCreated: preferences?.createCaseComment.toString()
		}
	}, [initialValues, preferences])

	if (languagesLoading)
		return (
			<SidebarForm footer={null}>
				<FormTitle>
					<SkeletonLoader width="50%" height={32} />
				</FormTitle>
				<FormFieldHorizontal>
					<FormRow doubleSpacing>
						<SkeletonLoader />
						<SkeletonLoader height={56} />
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow doubleSpacing>
						<SkeletonLoader />
						<SkeletonLoader count={2} width={35} height={41} />
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow doubleSpacing>
						<SkeletonLoader />
						<SkeletonLoader count={2} width={35} height={41} />
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow doubleSpacing>
						<SkeletonLoader />
						<SkeletonLoader count={2} width={35} height={41} />
					</FormRow>
				</FormFieldHorizontal>
			</SidebarForm>
		)
	if (languagesError)
		return (
			<SidebarForm footer={null}>
				{/* This error is displayed when fetching content from the CMS gives an error, therefore we cannot get a translated error message */}
				<ErrorState
					content={
						formErrorMessage ??
						'Something went wrong while retrieving the form data.'
					}
					showBowWave
				/>
			</SidebarForm>
		)

	return (
		<Formik
			initialValues={{ ...values }}
			enableReinitialize
			onSubmit={submitHandler}
			validationSchema={validationSchema(validation)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ handleSubmit, setFieldValue }) => {
				return (
					<SidebarForm
						footer={
							<FormFooter
								onBack={onSubmitted}
								labelDiscard={labelCancel}
								labelSaving={labelSaving && '[Saving...]'}
								labelSubmit={labelSave}
								isSending={sending}
								isLastStep
								submitColor="orange"
							/>
						}
						onSubmit={handleSubmit}
					>
						<PreferencesFormFields
							setFieldValue={setFieldValue}
							myUserAccount={myUserAccount}
							languages={languages}
							{...props}
						/>
					</SidebarForm>
				)
			}}
		</Formik>
	)
}

export default Preferences
