import { Validation } from '@components/UI/Forms/Validation/types'

export interface UserAccountModalProps {
	isOpen: boolean
	onClose: () => void
	setEditMode: () => void
	setReactivateMode: () => void
	type: UserAccountModalType
	userAccountId?: string
}

export interface ModalResponseProps {
	formContent: UserAccountContent
	validation: Validation
}

export interface UserAccountContent {
	[key: string]: string | string[] | any
}

export enum UserAccountModalType {
	Invite,
	Edit,
	View,
	Reactivate
}
