import React from 'react'

import { DashboardStoryblok } from '@graphql/storyblokcomponents'
import { DashboardRow } from './styles'
import ConnectedApps from './ConnectedApps'
import QuickLinks from './QuickLinks'

type Props = {
	content: DashboardStoryblok
}

const AppsAndLinks = ({ content }: Props) => {
	return (
		<DashboardRow>
			<ConnectedApps content={content} />

			<QuickLinks content={content} />
		</DashboardRow>
	)
}

export default AppsAndLinks
