import React, { FC, useState } from 'react'
import { FieldArray, useFormikContext } from 'formik'

import Checkbox from '@damen/ui/lib/cjs/components/Form/Checkbox'

import { FormLabel } from '@components/UI/Forms/FormUI'

import { CheckboxGroupError } from './styles'
import { FieldCheckboxGroupProps, CheckboxItem } from './types'

const FieldCheckboxGroup: FC<FieldCheckboxGroupProps> = ({
	hideLabel = false,
	label,
	name,
	options
}) => {
	const { setFieldValue } = useFormikContext()
	// Initialize state for checked items
	const [checkedItems, setCheckedItems] = useState(
		options.reduce((acc, item) => {
			acc[item.key] = item.selected
			return acc
		}, {} as Record<string, boolean>)
	)

	// Handle checkbox change
	const handleCheckboxChange = (key) => {
		setCheckedItems((prev) => {
			const newCheckedItems = {
				...prev,
				[key]: !prev[key]
			}
			// Update Formik field value
			setFieldValue(
				name,
				Object.keys(newCheckedItems).filter((k) => newCheckedItems[k])
			)
			return newCheckedItems
		})
	}

	return (
		<FieldArray
			name={name}
			render={({ form: { errors } }) => (
				<>
					{label && !hideLabel && (
						<FormLabel htmlFor={name}>{label}</FormLabel>
					)}
					{options?.map((item: CheckboxItem) => (
						<Checkbox
							key={item.key}
							type="checkbox"
							background="blue"
							name={name}
							label={item.value}
							checked={checkedItems[item.key]}
							onChange={() => handleCheckboxChange(item.key)}
						/>
					))}
					{errors[name] !== '' &&
						errors[name] !== undefined &&
						!hideLabel && (
							<CheckboxGroupError>{`${label} ${errors[name]}`}</CheckboxGroupError>
						)}
				</>
			)}
		/>
	)
}

export default FieldCheckboxGroup
