import { GetUserAccountOverviewQuery } from '@graphql/graphql'
import React from 'react'

// import ActiveFacets from '@components/ActiveFacets'
import SelectFacets from '@components/SelectFacets'
import StickyFilters from '@components/StickyFilters'

enum UserAccountFilterKeys {
	company = 'company'
}

export interface UserAccountFilters {
	[UserAccountFilterKeys.company]?: string
}

interface UserAccountOverviewFiltersProps {
	facets: GetUserAccountOverviewQuery['userAccounts']['facets']
	activeFilters: UserAccountFilters
	onChangeFilters: (filters: UserAccountFilters) => void
	companyFilterLabel: string
}

const UserAccountOverviewFilters = ({
	facets: facetsData,
	activeFilters,
	onChangeFilters,
	companyFilterLabel
}: UserAccountOverviewFiltersProps) => {
	const facets = [
		{
			key: UserAccountFilterKeys.company,
			label: companyFilterLabel,
			options: facetsData?.company,
			singleSelect: true
		}
	].filter(Boolean)

	return (
		<StickyFilters>
			<SelectFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
			/>

			{/* Uncommented for now as we have one filter which is already prefiltered (You always have the company filter active and cannot unselect) */}
			{/* <ActiveFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
			/> */}
		</StickyFilters>
	)
}

export default UserAccountOverviewFilters
