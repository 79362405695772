import * as Yup from 'yup'
import { Validation } from '@components/UI/Forms/Validation/types'

export const validationSchema = (messages: Validation) => {
	return Yup.object({
		language: Yup.string().required(messages.required),
		caseCreated: Yup.bool().required(messages.required),
		caseClosed: Yup.bool().required(messages.required),
		caseCommentCreated: Yup.bool().required(messages.required)
	})
}
