import React from 'react'

import Container from '@components/Container/Container'
import Spacer from '@components/Spacer'
import StickyFilters from '@components/StickyFilters'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import SearchSkeleton from '@skeletons/Search'
import CheckboxGroupSkeleton from '@skeletons/CheckboxGroup'

interface UserAccountCompaniesProps {
	amountOfItems?: number
	type?: 'checkbox' | 'radio'
}

const UserAccountCompaniesSkeleton = ({
	amountOfItems = 6,
	type = 'checkbox'
}: UserAccountCompaniesProps) => (
	<Container>
		<StickyFilters>
			<SkeletonLoader width="30%" height={14} />
			<SearchSkeleton />
		</StickyFilters>

		<Spacer paddingTop>
			<CheckboxGroupSkeleton amount={amountOfItems} type={type} />
		</Spacer>
	</Container>
)

export default UserAccountCompaniesSkeleton
