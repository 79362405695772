import styled from 'styled-components'
import { theme } from '@damen/ui'

export const SelectAnchor = styled.object`
	display: block;
	width: 100%;
	height: 0;
`

export const CheckboxGroupError = styled.p`
	color: ${theme.colors.blue};
	font-weight: 500;
	width: 100%;
	margin: 8px 0 0 19px;
	line-height: 1;
	font-size: 14px;
`
