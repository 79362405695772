import React from 'react'

import {
	PageItem,
	GetUserAccountOverviewPageDataQuery,
	GetUserAccountOverviewPageDataDocument,
	Permissions
} from '@graphql/graphql'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import useAuthorization from '@hooks/useAuthorization'

import Banner from '@components/Banner'
import PageHead from '@components/PageHead'
import UserAccountOverview from '@components/UserAccountOverview'
import { useUserAccountContext } from '@contexts/UserAccountContext'
import { BlokZone } from '@components/BlokZone'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData =
		await apolloClient.query<GetUserAccountOverviewPageDataQuery>({
			query: GetUserAccountOverviewPageDataDocument,
			variables: {
				language: locale
			}
		})

	return pageData?.data?.PageItem ?? {}
}

const UserAccountOverviewBanner = (props) => {
	const { openPanel } = useUserAccountContext()

	return (
		<Banner
			{...props}
			bannerLeftWidth="75%"
			bannerRight="25%"
			onClick={openPanel}
		/>
	)
}

const components = {
	header: UserAccountOverviewBanner,
	userAccountOverview: UserAccountOverview
}

const UserAccountOverviewScreen = ({ pageData }: { pageData: PageItem }) => {
	useAuthorization(Permissions.ManageUserAccountsRead)

	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			<BlokZone
				bloks={pageData?.content?.blocks}
				componentLookup={components}
			/>
		</>
	)
}

export default UserAccountOverviewScreen
