import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import { CheckboxSkeletonWrapper } from './styles'

interface CheckboxGroupProps {
	amount: number
	type?: 'checkbox' | 'radio'
}

const CheckboxGroupSkeleton = ({
	amount,
	type = 'checkbox'
}: CheckboxGroupProps) => {
	const checkboxItem = amount
	const checkboxId = []
	for (let i = 0; i < amount; i += 1) {
		checkboxId.push(i)
	}

	return (
		<>
			{[...Array(checkboxItem)].map((_item, i) => {
				return (
					<CheckboxSkeletonWrapper key={checkboxId[i]}>
						<SkeletonLoader
							width={20}
							height={21}
							circle={type === 'radio'}
						/>
						<SkeletonLoader width={100} height={21} />
					</CheckboxSkeletonWrapper>
				)
			})}
		</>
	)
}

export default CheckboxGroupSkeleton
