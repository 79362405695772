import React, { createContext, useMemo } from 'react'
import { DxpForm } from '@components/UI/Forms/types'

import { validationSchema } from './Validation'
import { initialValues } from './initialValues'

export const MySettingsPersonalDetailsStore = createContext<DxpForm>(null)

const MySettingsPersonalDetailsProvider = ({ children }) => {
	const context = useMemo(
		() => ({
			initialValues,
			validationSchema
		}),
		[]
	)

	return (
		<MySettingsPersonalDetailsStore.Provider value={context}>
			{children}
		</MySettingsPersonalDetailsStore.Provider>
	)
}

export default MySettingsPersonalDetailsProvider
