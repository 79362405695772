import React from 'react'
import { Icon } from '@damen/ui'

import { DashboardStoryblok } from '@graphql/storyblokcomponents'
import { getStoryblokLinkHref } from '@utils/linkResolver'
import { DashboardCard, ListItem, ListLink, CardTitle } from './styles'

type Props = {
	content: DashboardStoryblok
}

const AppsAndLinks = ({ content }: Props) => {
	return (
		content?.links &&
		content?.links?.length > 0 && (
			<DashboardCard>
				<CardTitle>{content?.labelQuickLinks}</CardTitle>
				<ul>
					{content?.links.map((link) => (
						<ListItem>
							<ListLink
								key={link._uid}
								href={getStoryblokLinkHref(link.link)}
							>
								{link.title}
								<Icon.CaretDown height={12} />
							</ListLink>
						</ListItem>
					))}
				</ul>
			</DashboardCard>
		)
	)
}

export default AppsAndLinks
