import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Formik } from 'formik'

import {
	GetMySettingsDocument,
	PhonenumberTypes,
	useEditMyUserAccountMutation
} from '@graphql/graphql'

import { MySettingsPersonalDetailsStore } from '@contexts/MySettingsPersonalDetails'
import { validationSchema } from '@contexts/MySettingsPersonalDetails/Validation'
import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import FormFooter from '@components/UI/Forms/FormFooter'
import SidebarForm from '@components/SidebarForm'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'

import useError from '@hooks/useError'

import PersonalDetailsFormFields from './PersonalDetailsFormFields'

import { mapValues } from './mapValues'
import { PersonalDetailsOptionItemProps, PersonalDetailsProps } from './types'

const PersonalDetails: FC<
	PersonalDetailsProps & PersonalDetailsOptionItemProps
> = (props) => {
	// Cached properties
	const {
		editPersonalDetailsSuccess,
		editPersonalDetailsError,
		labelCancel,
		labelSave,
		labelSaving,
		onSubmitted,
		validation,
		myUserAccount
	} = props

	// Contexts
	const { initialValues } = useContext(MySettingsPersonalDetailsStore)
	const { sendNotification } = useNotificationContext()
	const { showError } = useError()

	// Local state
	const [sending, setSending] = useState(false)

	// APIs
	const [editMyUserAccount] = useEditMyUserAccountMutation({
		refetchQueries: [GetMySettingsDocument]
	})

	const values = useMemo(() => {
		const filteredEmail = myUserAccount?.emails.filter((f) => f.primary)

		const filteredMobile =
			myUserAccount?.phoneNumbers?.filter(
				(f) => f.type === PhonenumberTypes.Mobile
			) ?? []

		const filteredPhone =
			myUserAccount?.phoneNumbers?.filter(
				(f) => f.type === PhonenumberTypes.Work
			) ?? []

		return {
			...initialValues,
			firstName: myUserAccount?.name?.firstName ?? '',
			lastName: myUserAccount?.name?.lastName ?? '',
			email: filteredEmail?.[0]?.email ?? '',
			mobile: filteredMobile?.[0]?.phonenumber ?? '',
			phone: filteredPhone?.[0]?.phonenumber ?? ''
		}
	}, [initialValues, myUserAccount])

	type FormValues = typeof values
	const submitHandler = useCallback(
		async (formValues: FormValues) => {
			setSending(true)

			const mappedFormValues = mapValues(formValues)

			try {
				const response = await editMyUserAccount({
					variables: {
						request: mappedFormValues
					}
				})

				if (response.data?.editMyUserAccount?.id) {
					sendNotification(
						NotificationTypes.SNACKBAR,
						{
							snackbarType: SnackbarNotificationTypes.SUCCESS,
							title:
								editPersonalDetailsSuccess ??
								'[Personal details updated successfully!]'
						},
						{
							showAfterDelay: 2000
						}
					)

					if (onSubmitted) {
						onSubmitted()
					}
				} else {
					setSending(false)

					showError(
						editPersonalDetailsError ??
							'[There was an error. Please try again later]'
					)
				}
			} catch (requestError) {
				setSending(false)

				showError(
					editPersonalDetailsError ??
						'[There was an error. Please try again later]'
				)

				if (onSubmitted) {
					onSubmitted()
				}
			}
		},
		[
			editMyUserAccount,
			editPersonalDetailsError,
			editPersonalDetailsSuccess,
			onSubmitted,
			sendNotification,
			showError
		]
	)

	return (
		<Formik
			initialValues={values}
			enableReinitialize
			onSubmit={submitHandler}
			validationSchema={validationSchema(validation)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ handleSubmit, setFieldValue }) => {
				return (
					<SidebarForm
						footer={
							<FormFooter
								onBack={onSubmitted}
								labelDiscard={labelCancel}
								labelSaving={labelSaving ?? '[Saving...]'}
								labelSubmit={labelSave}
								isSending={sending}
								isLastStep
								submitColor="orange"
							/>
						}
						onSubmit={handleSubmit}
					>
						<PersonalDetailsFormFields
							{...props}
							setFieldValue={setFieldValue}
							myUserAccount={myUserAccount}
						/>
					</SidebarForm>
				)
			}}
		</Formik>
	)
}

export default PersonalDetails
