import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import {
	GetPageDataDocument,
	GetPageDataQuery,
	PageItem
} from '@graphql/graphql'

import Banner from '@components/Banner'
import PageHead from '@components/PageHead'
import { BlokZone } from '@components/BlokZone'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string,
	slug: string
) {
	const pageData = await apolloClient.query<GetPageDataQuery>({
		query: GetPageDataDocument,
		variables: {
			language: locale,
			slug
		}
	})

	return pageData?.data?.PageItem ?? {}
}

const componentsLookup = {
	header: Banner
}

const Content = ({ pageData }: { pageData: PageItem }) => {
	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			<BlokZone
				bloks={pageData?.content?.blocks}
				componentLookup={componentsLookup}
			/>
		</>
	)
}

export default Content
