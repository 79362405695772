import { theme } from '@damen/ui'
import styled from 'styled-components'

// Styled elements
export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;
`

export const FooterWrapper = styled.div`
	margin-top: auto;
	padding-top: 42px;
	border-top: 1px solid ${theme.colors.greyAccentLight};
`

export const LoadableCompaniesWrapper = styled.div`
	max-height: 366px;
	overflow: auto;
`
