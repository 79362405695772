import React, { FC, useCallback } from 'react'
import SidebarForm from '@components/SidebarForm'

import { Button, Icon } from '@damen/ui'
import { Permissions } from '@graphql/graphql'
import { usePermissions } from '@hooks/usePermission'
import { DataListRow } from '../helpers'
import {
	DataList,
	FooterWrapper,
	IntroWrapper,
	InfoMessage,
	Title
} from '../styles'
import { NoAccessFormProps } from '../types'

const NoAccessForm: FC<NoAccessFormProps> = ({
	blok,
	userData,
	setReactivateMode
}) => {
	// Cached values
	const { noAccessNotification, reactivateAccount } = blok

	const { hasAcquired: hasAcquiredManageUserAccountWrite } = usePermissions(
		Permissions.ManageUserAccountsWrite
	)

	const buttonClickHandler = useCallback(async () => {
		setReactivateMode()
	}, [setReactivateMode])

	return (
		<SidebarForm
			footer={
				hasAcquiredManageUserAccountWrite && (
					<FooterWrapper isSmaller>
						<Button.Default
							text={reactivateAccount ?? '[Reactivate Account]'}
							buttonType="text"
							icon={Icon.LeftChevron}
							iconPosition="right"
							onClick={buttonClickHandler}
						/>
					</FooterWrapper>
				)
			}
		>
			<IntroWrapper>
				<Title>{userData.name}</Title>
			</IntroWrapper>
			<InfoMessage content={noAccessNotification} fullWidth />
			<DataList>
				<DataListRow
					labelSingle={blok.phoneNumber}
					labelMultiple={blok.phoneNumbers}
					values={userData.phoneNumbers}
				/>
				<DataListRow
					labelSingle={blok.emailAdress}
					labelMultiple={blok.emailaddresses}
					values={userData.emails}
				/>
				{userData?.primaryCompanyName && (
					<DataListRow
						labelSingle={
							blok.labelPrimaryCompany ?? '[Primary Company]'
						}
						labelMultiple={
							blok.labelPrimaryCompany ?? '[Primary Company]'
						}
						values={[userData.primaryCompanyName]}
					/>
				)}
				{userData?.jobTitle && (
					<DataListRow
						labelSingle={blok.jobTitle}
						labelMultiple={blok.jobTitle}
						values={[userData.jobTitle]}
					/>
				)}
				{userData?.department && (
					<DataListRow
						labelSingle={blok.department}
						labelMultiple={blok.department}
						values={[userData.department]}
					/>
				)}
				<DataListRow
					labelSingle={blok.companies}
					labelMultiple={blok.companies}
					values={userData.companies}
				/>
				{userData?.status && (
					<DataListRow
						labelSingle={blok.status ?? '[Account status]'}
						labelMultiple={blok.status ?? '[Account status]'}
						values={[userData.status]}
					/>
				)}
				{userData?.identity && (
					<DataListRow
						labelSingle={blok.identity ?? '[Identity]'}
						labelMultiple={blok.identity ?? '[Identity]'}
						values={[userData.identity]}
					/>
				)}
				{userData?.createdDateTimeFormatted && (
					<DataListRow
						labelSingle={blok.createdDateTime ?? '[Date created]'}
						labelMultiple={blok.createdDateTime ?? '[Date created]'}
						values={[userData.createdDateTimeFormatted]}
					/>
				)}
				{userData?.lastSignInDateTimeFormatted && (
					<DataListRow
						labelSingle={
							blok.lastSignInDateTime ?? '[Last login date]'
						}
						labelMultiple={
							blok.lastSignInDateTime ?? '[Last login date]'
						}
						values={[userData.lastSignInDateTimeFormatted]}
					/>
				)}
			</DataList>
		</SidebarForm>
	)
}

export default NoAccessForm
