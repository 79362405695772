import React from 'react'

import Sidebar from '@components/Sidebar'

import MySettingsPersonalDetailsProvider from '@contexts/MySettingsPersonalDetails'

import { PreferencesProps } from '@components/Modals/MySettingsModal/components/Preferences/types'
import Preferences from '@components/Modals/MySettingsModal/components/Preferences'
import PersonalDetails from '@components/Modals/MySettingsModal/components/PersonalDetails'
import {
	PersonalDetailsOptionItemProps,
	PersonalDetailsProps
} from '@components/Modals/MySettingsModal/components/PersonalDetails/types'
import { MySettingsProps, MySettingForms } from '@components/MySettings/types'

import { MySettingsModalProps } from '@components/Modals/MySettingsModal/types'
import MySettingsPreferencesProvider from '@contexts/MySettingsPreferences'

const MySettingsModal: React.FC<MySettingsProps & MySettingsModalProps> = ({
	activeForm,
	blok,
	formKey,
	isOpen,
	onClose,
	onSubmitted,
	validation,
	myUserAccount
}) => (
	<Sidebar isOpen={isOpen} onClose={onClose}>
		{activeForm === MySettingForms.PERSONAL_DETAILS && (
			<MySettingsPersonalDetailsProvider key={formKey}>
				<PersonalDetails
					validation={validation}
					onSubmitted={onSubmitted}
					myUserAccount={myUserAccount}
					{...(blok as unknown as PersonalDetailsProps &
						PersonalDetailsOptionItemProps)}
				/>
			</MySettingsPersonalDetailsProvider>
		)}
		{activeForm === MySettingForms.PREFERENCES && (
			<MySettingsPreferencesProvider key={formKey}>
				<Preferences
					key={formKey}
					validation={validation}
					onSubmitted={onSubmitted}
					myUserAccount={myUserAccount}
					{...(blok as unknown as PreferencesProps)}
				/>
			</MySettingsPreferencesProvider>
		)}
	</Sidebar>
)

export default MySettingsModal
