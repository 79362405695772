import { theme } from '@damen/ui'

const userAccountColorMapping = {
	Active: theme.colors.green,
	Invited: theme.colors.yellow,
	Blocked: theme.colors.red,
	Inactive: theme.colors.orange
}

export default userAccountColorMapping
