import React, { useCallback, useContext, useMemo } from 'react'
import {
	GetCompaniesQuery,
	GetCompaniesQueryVariables,
	UserAccountCompanyOrder
} from '@graphql/graphql'

import { InviteUserAccountStore } from '@contexts/InviteUserAccountContext'
import { mapCompany } from '@contexts/InviteUserAccountContext/helpers'

import useScrollToFieldError from '@hooks/useScrollToFieldError'

import FieldInput from '@components/UI/Forms/FieldInput'
import FieldRadioGroup from '@components/UI/Forms/FieldRadioGroup'
import FieldCheckboxGroup from '@components/UI/Forms/FieldCheckboxGroup'
import FormHeader from '@components/UI/Forms/FormHeader'
import {
	FormFieldHorizontal,
	FormLabel,
	FormRow,
	FormTip
} from '@components/UI/Forms/FormUI'
import StickyFilters from '@components/StickyFilters'
import GenericOverview from '@components/GenericOverview'
import { DefaultGenericError } from '@components/GenericOverview/components/GenericError/DefaultGenericError'
import FormSearchField from '@components/UI/FormSearchField'
import { CheckboxGroupError } from '@components/UI/Forms/FieldCheckboxGroup/styles'
import UserAccountCompaniesSkeleton from '@skeletons/UserAccountCompanies'
import { GeneralInviteUserAccountProps } from './types'
import { LoadableCompaniesWrapper } from '../styles'

const REQUIRED_FORM_FIELDS = ['email', 'companies', 'authorization', 'lastname']

const GeneralFormFields = ({
	content,
	errors,
	setFieldValue
}: GeneralInviteUserAccountProps) => {
	// Contexts
	const { initialValues, loadCompanies } = useContext(InviteUserAccountStore)
	// Must be larger then viewPort height
	const companiesPageSize = 12

	// Cached properties
	const {
		title,
		labelEmail,
		labelEmailTip,
		placeholderEmail,
		labelAuthorization,
		labelCompany,
		labelCompanyTip,
		labelFirstname,
		labelFirstnameTip,
		placeholderFirstname,
		labelLastname,
		labelLastnameTip,
		placeholderLastname,
		labelPrimaryCompany,
		labelPrimaryCompanyTip,
		emptyTitle,
		emptyContent,
		searchNotFoundTitle,
		searchNotFoundContent,
		errorTitle,
		errorContent,
		errorButtonContent,
		placeholderSearchCompany
	} = content
	const { authorization } = initialValues
	const memoizedAuthorization = useMemo(
		() =>
			authorization
				?.map((accessRight) => {
					return {
						key: accessRight.id,
						value: accessRight.name,
						description: accessRight.description
					}
				})
				.sort((a, b) => (a.value > b.value ? 1 : -1)),
		[authorization]
	)

	useScrollToFieldError(errors, REQUIRED_FORM_FIELDS)

	return (
		<>
			<FormHeader title={title ?? '[Invite new user]'} />
			<FormFieldHorizontal>
				<FormRow>
					<FieldInput
						name="email"
						label={labelEmail ?? '[Email]'}
						placeholder={
							placeholderEmail ?? `[Enter the user's email here.]`
						}
						setFieldValue={setFieldValue}
					/>
					<FormTip>{labelEmailTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldInput
						name="firstname"
						label={labelFirstname ?? '[Firstname]'}
						placeholder={
							placeholderFirstname ??
							`[Enter the user's name here.]`
						}
						setFieldValue={setFieldValue}
					/>
					<FormTip>{labelFirstnameTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldInput
						name="lastname"
						label={labelLastname ?? '[Lastname]'}
						placeholder={
							placeholderLastname ??
							`[Enter the user's last name here.]`
						}
						setFieldValue={setFieldValue}
					/>
					<FormTip>{labelLastnameTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			{/* Primary Companydropdown */}
			<FormFieldHorizontal>
				<FormRow>
					<LoadableCompaniesWrapper>
						<GenericOverview<
							GetCompaniesQueryVariables,
							GetCompaniesQuery,
							null
						>
							key="companyDropdown"
							loadType="infinite"
							loadData={useCallback(
								async ({ searchQuery, page }) =>
									loadCompanies({
										variables: {
											searchText: searchQuery,
											skip:
												(page - 1) * companiesPageSize,
											limit: companiesPageSize,
											order: UserAccountCompanyOrder.NameDesc
										}
									}),
								[loadCompanies, companiesPageSize]
							)}
							mergeData={(prev, next) => ({
								...prev,
								manageableCompanies: {
									...prev.manageableCompanies,
									items: [
										...prev.manageableCompanies.items,
										...next.manageableCompanies.items
									]
								}
							})}
							getTotalPages={(data) =>
								data.manageableCompanies.total /
								companiesPageSize
							}
							renderHeader={({ searchQuery, setSearchQuery }) => (
								<StickyFilters>
									<FormLabel htmlFor="primaryCompany">
										{labelPrimaryCompany ??
											'[Primary Company]'}
									</FormLabel>
									<FormSearchField
										value={searchQuery}
										onChange={setSearchQuery}
										placeholder={placeholderSearchCompany}
									/>
								</StickyFilters>
							)}
							renderTable={({ data }) => (
								<FieldRadioGroup
									name="primaryCompany"
									label={
										labelPrimaryCompany ??
										'[Primary Company]'
									}
									hideLabel
									options={data.manageableCompanies.items.map(
										(item) =>
											mapCompany(
												item,
												data.manageableCompanies.items
													.length === 1
											)
									)}
									setFieldValue={setFieldValue}
								/>
							)}
							renderError={({ type, searchQuery }) => (
								<DefaultGenericError
									type={type}
									searchQuery={searchQuery}
									translations={{
										emptyTitle,
										emptyContent,
										searchNotFoundTitle,
										searchNotFoundContent,
										errorTitle,
										errorContent,
										errorButtonContent
									}}
								/>
							)}
							skeleton={
								<UserAccountCompaniesSkeleton type="radio" />
							}
						/>
					</LoadableCompaniesWrapper>
					{errors.primaryCompany !== '' &&
						errors.primaryCompany !== undefined && (
							<CheckboxGroupError>{`${
								labelPrimaryCompany ?? '[Primary Company]'
							} ${errors.primaryCompany}`}</CheckboxGroupError>
						)}
					<FormTip>{labelPrimaryCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			{/* Company Access dropdown */}
			<FormFieldHorizontal>
				<FormRow>
					<LoadableCompaniesWrapper>
						<GenericOverview<
							GetCompaniesQueryVariables,
							GetCompaniesQuery,
							null
						>
							key="accessDropdown"
							loadType="infinite"
							loadData={useCallback(
								async ({ searchQuery, page }) =>
									loadCompanies({
										variables: {
											searchText: searchQuery,
											skip:
												(page - 1) * companiesPageSize,
											limit: companiesPageSize,
											order: UserAccountCompanyOrder.NameDesc
										}
									}),
								[loadCompanies, companiesPageSize]
							)}
							mergeData={(prev, next) => ({
								...prev,
								manageableCompanies: {
									...prev.manageableCompanies,
									items: [
										...prev.manageableCompanies.items,
										...next.manageableCompanies.items
									]
								}
							})}
							getTotalPages={(data) =>
								data.manageableCompanies.total /
								companiesPageSize
							}
							renderHeader={({ searchQuery, setSearchQuery }) => (
								<StickyFilters>
									<FormLabel htmlFor="companies">
										{labelCompany ?? '[Company access]'}
									</FormLabel>
									<FormSearchField
										value={searchQuery}
										onChange={setSearchQuery}
										placeholder={placeholderSearchCompany}
									/>
								</StickyFilters>
							)}
							renderTable={({ data }) => (
								<FieldCheckboxGroup
									name="companies"
									hideLabel
									label={labelCompany ?? '[Company access]'}
									options={data.manageableCompanies.items.map(
										(item) => mapCompany(item, null)
									)}
									setFieldValue={setFieldValue}
								/>
							)}
							renderError={({ type, searchQuery }) => (
								<DefaultGenericError
									type={type}
									searchQuery={searchQuery}
									translations={{
										emptyTitle,
										emptyContent,
										searchNotFoundTitle,
										searchNotFoundContent,
										errorTitle,
										errorContent,
										errorButtonContent
									}}
								/>
							)}
							skeleton={<UserAccountCompaniesSkeleton />}
						/>
					</LoadableCompaniesWrapper>
					{errors.companies !== '' &&
						errors.companies !== undefined && (
							<CheckboxGroupError>{`${
								labelCompany ?? '[Company access]'
							} ${errors.companies}`}</CheckboxGroupError>
						)}
					<FormTip>{labelCompanyTip}</FormTip>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldRadioGroup
						name="authorization"
						label={labelAuthorization ?? '[Authorization]'}
						options={memoizedAuthorization}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default GeneralFormFields
