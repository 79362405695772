import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import { Icon, theme } from '@damen/ui'
import { useLocale } from '@hooks/useLocale'
import {
	CaseCategories,
	CaseStatuses,
	useGetServiceRequestDashboardFacetsQuery
} from '@graphql/graphql'
import { SERVICEREQUESTS } from '@src/constants/Routes'

import { TableIconWrapper } from '@components/GenericOverview/styles'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import ErrorState from '@components/UI/Error'
import GraphSkeleton from '@skeletons/Graph'
import BarChart, { Bar } from './BarChart'
import {
	DashboardCard,
	ServiceRequestStatsTitle,
	StyledSeeAllButton,
	TotalCount
} from './styles'

const StyledBarChart = styled(BarChart)`
	background: ${theme.colors.greyAccentLight};
	padding: 32px;
`

const StyledTableIconWrapper = styled(TableIconWrapper)`
	position: absolute;
	top: 24px;
	right: 24px;
	cursor: pointer;
`

const categoryColors = {
	[CaseCategories.Warranty]: theme.colors.blue,
	[CaseCategories.TechnicalAssistance]: theme.colors.orange,
	[CaseCategories.RequestForInformation]: theme.colors.marineBlack,
	[CaseCategories.Training]: theme.colors.yellow
}

const AllowedCaseCategories = Object.keys(categoryColors) as CaseCategories[]

interface Props {
	title: ReactNode
	seeAll: string
	statuses: CaseStatuses[]
	errorMessage: string
	iconClickHandler: () => void
	emptyComponent: JSX.Element
}

const ServiceRequestStats = ({
	title,
	seeAll,
	statuses,
	errorMessage,
	emptyComponent: EmptyComponent,
	iconClickHandler
}: Props) => {
	const { locale } = useLocale()
	const { data, loading, error } = useGetServiceRequestDashboardFacetsQuery({
		variables: {
			language: locale,
			statuses,
			categories: AllowedCaseCategories
		}
	})

	const highestCategoryCount =
		data?.cases.facets.category.reduce(
			(highest, { amount }) => Math.max(highest, amount),
			0
		) || 1 // default to 1 so the graph always shows something

	const categories = data?.cases.facets.category
	const allowedCategories = data?.cases.facets.category
	const getCategoryValue = (category: string) =>
		categories?.find(
			({ id }) => id.toLocaleLowerCase() === category.toLocaleLowerCase()
		)?.amount ?? 0

	const router = useRouter()
	const serviceRequestsClickHandler = useCallback(() => {
		router.push(`${SERVICEREQUESTS}?status=${statuses.join(',')}`)
	}, [router, statuses])

	if (loading) {
		return (
			<DashboardCard>
				<ServiceRequestStatsTitle>
					<SkeletonLoader width={317} height={36} />
				</ServiceRequestStatsTitle>
				<GraphSkeleton />
			</DashboardCard>
		)
	}

	if (error) {
		return (
			<DashboardCard>
				<ServiceRequestStatsTitle>{title}</ServiceRequestStatsTitle>
				<ErrorState
					content={
						errorMessage ??
						'Something went wrong while retrieving the graph data'
					}
					showBowWave
				/>
			</DashboardCard>
		)
	}

	return (
		<DashboardCard>
			<ServiceRequestStatsTitle>
				{title}
				{data?.cases.total > 0 && (
					<TotalCount>{data?.cases.total}</TotalCount>
				)}
				{iconClickHandler && data?.cases.total > 0 && (
					<StyledTableIconWrapper
						onClick={(event) => {
							event.stopPropagation()
							iconClickHandler()
						}}
					>
						<Icon.Info
							fill={theme.colors.grey}
							height="16"
							width="16"
						/>
					</StyledTableIconWrapper>
				)}
			</ServiceRequestStatsTitle>

			{data?.cases.total > 0 && (
				<>
					<StyledBarChart highestValue={highestCategoryCount}>
						{allowedCategories.map((category) => (
							<Bar
								key={category.id}
								label={category.name}
								value={getCategoryValue(category.id)}
								color={categoryColors[category.id]}
							/>
						))}
					</StyledBarChart>

					<StyledSeeAllButton
						text={seeAll}
						onClick={serviceRequestsClickHandler}
						buttonType="text"
						colorType="blue"
						icon={Icon.LeftChevron}
						iconPosition="right"
						iconSize={14}
					/>
				</>
			)}

			{(!data || data?.cases?.total <= 0) && EmptyComponent}
		</DashboardCard>
	)
}

export default ServiceRequestStats
