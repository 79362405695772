import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'

const Wrapper = styled.div`
	position: relative;
	display: block;
	padding: ${theme.layout.spacingM} 0;
`

const Content = styled.div`
	position: relative;
	display: block;
	color: ${theme.colors.marineBlack};
	font-size: ${theme.typography.fontSizeText}px;
	line-height: 1.88;
	margin-top: ${theme.layout.spacingXL};
`

const ChildenWrapper = styled.div`
	position: relative;
	display: block;
	margin-top: ${theme.layout.spacingXL};
`

interface Props {
	content: string
	children?: React.ReactNode
}

const EmptyState: React.FC<Props> = ({ content, children }) => (
	<Wrapper>
		<Icon.BowWave width={86} height={64} fill="#2546b1" />
		<Content>{content}</Content>
		{children && <ChildenWrapper>{children}</ChildenWrapper>}
	</Wrapper>
)

export default EmptyState
