import React from 'react'

import FieldInput from '@components/UI/Forms/FieldInput'
import FormHeader from '@components/UI/Forms/FormHeader'
import { FormFieldHorizontal, FormRow } from '@components/UI/Forms/FormUI'
import { PersonalDetailsFormFieldsProps, PersonalDetailsProps } from './types'

const PersonalDetailsFormFields: React.FC<
	PersonalDetailsFormFieldsProps & PersonalDetailsProps
> = ({
	editPersonalDetailsTitle,
	labelPersonalFirstName,
	labelPersonalLastName,
	labelPersonalMobileNumber,
	labelPersonalEmail,
	labelPersonalPhoneNumber,
	placeholderPersonalFirstName,
	placeholderPersonalLastName,
	placeholderPersonalEmail,
	placeholderPersonalMobileNumber,
	placeholderPersonalPhoneNumber,
	setFieldValue
}) => {
	return (
		<>
			<FormHeader
				doubleSpacing
				title={editPersonalDetailsTitle ?? '[Edit personal details]'}
			/>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldInput
						name="firstName"
						label={labelPersonalFirstName ?? '[First name]'}
						placeholder={
							placeholderPersonalFirstName ??
							'[Please enter your first name]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldInput
						name="lastName"
						label={labelPersonalLastName ?? '[Last name]'}
						placeholder={
							placeholderPersonalLastName ??
							'[Please enter your first lastname]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldInput
						name="email"
						label={labelPersonalEmail ?? '[Email]'}
						placeholder={
							placeholderPersonalEmail ??
							'[Please enter your first lastname]'
						}
						readOnly
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldInput
						name="phone"
						label={labelPersonalPhoneNumber ?? '[Phone]'}
						placeholder={
							placeholderPersonalPhoneNumber ??
							'[Please enter your phone number]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldInput
						name="mobile"
						label={labelPersonalMobileNumber ?? '[Mobile]'}
						placeholder={
							placeholderPersonalMobileNumber ??
							'[Please enter your mobile number]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default PersonalDetailsFormFields
