import React from 'react'

import Sidebar from '@components/Sidebar'
import InviteUserAccountProvider from '@contexts/InviteUserAccountContext'
import EditUserAccountProvider from '@contexts/EditUserAccountContext'
import ReactivateUserAccountProvider from '@contexts/ReactivateUserAccountContext'
import InviteUserAccountForm from './components/InviteUserAccountForm'

import { UserAccountModalProps, UserAccountModalType } from './types'
import ViewUserAccount from './components/ViewUserAccount'
import EditUserAccountForm from './components/EditUserAccountForm'
import ReactivateUserAccountForm from './components/ReactivateUserAccountForm'

const UserAccountModal = ({
	isOpen,
	onClose,
	setEditMode,
	setReactivateMode,
	type,
	userAccountId
}: UserAccountModalProps) => {
	return (
		<Sidebar isOpen={isOpen} onClose={onClose}>
			{type === UserAccountModalType.View && (
				<ViewUserAccount
					onClose={onClose}
					setEditMode={setEditMode}
					setReactivateMode={setReactivateMode}
					userAccountId={userAccountId}
				/>
			)}
			{type === UserAccountModalType.Invite && (
				<InviteUserAccountProvider>
					<InviteUserAccountForm onFormSubmit={onClose} />
				</InviteUserAccountProvider>
			)}
			{type === UserAccountModalType.Edit && (
				<EditUserAccountProvider userAccountId={userAccountId}>
					<EditUserAccountForm onFormSubmit={onClose} />
				</EditUserAccountProvider>
			)}
			{type === UserAccountModalType.Reactivate && (
				<ReactivateUserAccountProvider userAccountId={userAccountId}>
					<ReactivateUserAccountForm onFormSubmit={onClose} />
				</ReactivateUserAccountProvider>
			)}
		</Sidebar>
	)
}

export default UserAccountModal
