import styled from 'styled-components'

import { Button, theme } from '@damen/ui'

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	padding: 38px 22px 24px;
	background-color: ${theme.colors.white};
	margin-top: 38px;

	@media ${theme.legacyMediaQueries.md} {
		padding: 30px 30px 25px;
		width: 100%;
		max-width: 1188px;
		margin: 45px auto 0;
	}

	@media ${theme.legacyMediaQueries.lg} {
		padding: 60px 60px 50px;
		margin: 45px auto 0;
	}
`

export const CardHeader = styled.div`
	@media ${theme.legacyMediaQueries.md} {
		display: flex;
		border-bottom: 1px solid ${theme.colors.greyAccentLight};
	}
`

export const CardHeaderBody = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
`

export const CardHeaderTitle = styled.h2`
	font-size: 30px;
	line-height: 1.4;
	letter-spacing: normal;
	margin: 0 0 8px;
	color: ${theme.colors.marineBlack};
`

export const CardHeaderText = styled.p`
	font-size: 16px;
	line-height: 1.88;
	margin: 0 0 24px;
	color: ${theme.colors.marineBlack};

	@media ${theme.legacyMediaQueries.md} {
		margin: 0 0 40px;
	}
`

export const CardHeaderButton = styled.div`
	border-bottom: 1px solid ${theme.colors.greyAccentLight};
	padding-bottom: 32px;

	@media ${theme.legacyMediaQueries.md} {
		border-bottom: 0;
	}
`

export const CardBody = styled.div`
	padding-top: 32px;
`

export const CardSubheading = styled.h3`
	font-size: 20px;
	line-height: 32px;
	letter-spacing: normal;
	margin: 0 0 16px;
	color: ${theme.colors.marineBlack};
`

export const CardItem = styled.div`
	display: block;
	padding: 24px 0;
	border-bottom: 1px solid ${theme.colors.greyAccentLight};

	&:last-of-type {
		padding-bottom: 0;
		border-bottom: 0;
	}
`

export const CardItemText = styled.p`
	margin: 0 0 24px;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
`
export const CardItemButton = styled(Button.Default)`
	font-size: 14px;
`
