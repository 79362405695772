import React, { FC } from 'react'
import { Button, Icon } from '@damen/ui'
import SidebarForm from '@components/SidebarForm'
import { DataListRow } from '../helpers'
import {
	DataList,
	FooterWrapper,
	FooterWrapperLeft,
	InfoMessage,
	IntroWrapper,
	Title
} from '../styles'
import { BlockedFormProps } from '../types'

const BlockedForm: FC<BlockedFormProps> = ({ blok, onClose, userData }) => {
	// Cached values
	const { closePanel, blockedNotification } = blok

	return (
		<SidebarForm
			footer={
				<FooterWrapper>
					<FooterWrapperLeft>
						<Button.Default
							text={closePanel ?? '[Close]'}
							icon={Icon.LeftChevron}
							iconPosition="right"
							buttonType="text"
							colorType="blue"
							onClick={onClose}
						/>
					</FooterWrapperLeft>
				</FooterWrapper>
			}
		>
			<IntroWrapper>
				<Title>{userData.name}</Title>
			</IntroWrapper>
			<InfoMessage content={blockedNotification} fullWidth />
			<DataList>
				<DataListRow
					labelSingle={blok.phoneNumber}
					labelMultiple={blok.phoneNumbers}
					values={userData.phoneNumbers}
				/>
				<DataListRow
					labelSingle={blok.emailAdress}
					labelMultiple={blok.emailaddresses}
					values={userData.emails}
				/>
				{userData?.jobTitle && (
					<DataListRow
						labelSingle={blok.jobTitle}
						labelMultiple={blok.jobTitle}
						values={[userData.jobTitle]}
					/>
				)}
				{userData?.department && (
					<DataListRow
						labelSingle={blok.department}
						labelMultiple={blok.department}
						values={[userData.department]}
					/>
				)}
				{userData?.company && (
					<DataListRow
						labelSingle={blok.company}
						labelMultiple={blok.company}
						values={[userData.company]}
					/>
				)}
				<DataListRow
					labelSingle={blok.companies}
					labelMultiple={blok.companies}
					values={userData.companies}
				/>
				{userData?.accessRight && (
					<DataListRow
						labelSingle={blok.authorization}
						labelMultiple={blok.authorization}
						values={[userData.accessRight]}
					/>
				)}
			</DataList>
		</SidebarForm>
	)
}

export default BlockedForm
