// TODO import should be import * as Yup from 'yup'
import Yup from '@utils/customYupValidations'
import { ValidationRules } from '@components/UI/Forms/Validation'
import { Validation } from '@components/UI/Forms/Validation/types'
import {
	ValidationRegexes,
	maxFirstNameMessage,
	maxLastNameMessage,
	maxPhoneMessage,
	minMessage
} from '@components/UI/Forms/Validation/rules'

export const validationSchema = (messages: Validation) => {
	return Yup.object({
		firstName: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.max(ValidationRules.maxFirstName, maxFirstNameMessage(messages)),
		lastName: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.max(ValidationRules.maxLastName, maxLastNameMessage(messages)),
		phone: Yup.string()
			.matches(ValidationRegexes.phone, {
				message: messages.isNumber
			})
			.noWhitespace(minMessage(messages))
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxPhoneNumber, maxPhoneMessage(messages)),
		mobile: Yup.string()
			.matches(ValidationRegexes.phone, {
				message: messages.isNumber
			})
			.noWhitespace(minMessage(messages))
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxPhoneNumber, maxPhoneMessage(messages))
	})
}
