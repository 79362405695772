import React from 'react'

const BarChartBackground = (props) => (
	<svg
		width="414"
		height="200"
		viewBox="0 0 414 200"
		preserveAspectRatio="none"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x="0.25"
			y="0.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			x="0.25"
			y="33.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			x="0.25"
			y="66.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			x="0.25"
			y="99.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			x="0.25"
			y="132.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			x="0.25"
			y="165.25"
			width="413.5"
			height="0.5"
			stroke="#B3B9C2"
			strokeWidth="0.5"
			strokeDasharray="2 2"
		/>
		<rect
			width="414"
			height="2"
			transform="translate(0 198)"
			fill="#011736"
		/>
	</svg>
)

export default BarChartBackground
