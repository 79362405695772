import { PageItem } from '@graphql/graphql'
import { UserAccountOverviewStoryblok } from '@graphql/storyblokcomponents'
import {
	UserAccountData,
	UserAccountFilterValueResolver,
	UserAccountColumnKeys,
	UserAccountSortingValueResolver,
	UserAccountColumnResolver
} from './types'

// Resolvers
export const filterResolver: UserAccountFilterValueResolver = {
	accessRight: (UserAccountModel: UserAccountData) =>
		UserAccountModel.accessRight?.name,
	status: (UserAccountModel: UserAccountData) => UserAccountModel.status.id
}

export const sortingResolver: UserAccountSortingValueResolver = {
	name: 'name.fullName',
	accessRight: 'accessRight.name',
	company: 'work.company',
	status: 'status.id'
}

export const tableColumnResolver: UserAccountColumnResolver = {
	name: UserAccountColumnKeys.name,
	company: UserAccountColumnKeys.company,
	status: UserAccountColumnKeys.status,
	createdDateTime: UserAccountColumnKeys.createdDateTime,
	lastSignInDateTime: UserAccountColumnKeys.lastSignInDateTime
}

export const getUserAccountOverviewBlok = (
	pageData: PageItem
): UserAccountOverviewStoryblok => {
	const blok = pageData?.content?.blocks.filter(
		(block) => block.component === 'userAccountOverview'
	)[0]

	return blok || null
}
