import React, { FC, useCallback, useState } from 'react'

import { useRevokeUserAccountAccessMutation } from '@graphql/graphql'

import { Button, Icon } from '@damen/ui'

import { useNotificationContext } from '@contexts/NotificationContext'
import AlertModal from '@components/Modals/AlertModal'
import { AssetButtonTypes } from '@components/Modals/AlertModal/types'
import { NotificationTypes } from '@contexts/NotificationContext/types'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import SidebarForm from '@components/SidebarForm'

import useError from '@hooks/useError'
import { usePermissions } from '@hooks/usePermission'
import { DataListRow } from '../helpers'
import {
	DataList,
	FooterWrapper,
	FooterWrapperLeft,
	IntroWrapper,
	Title
} from '../styles'
import { ActiveFormProps } from '../types'

const ActiveForm: FC<ActiveFormProps> = ({
	blok,
	onClose,
	setEditMode,
	userData,
	requiredPermissions
}) => {
	// Cached values
	const { editUser, deactivateUser, successfullyRevoked } = blok

	// Contexts
	const { sendNotification } = useNotificationContext()

	// Hooks
	const { showError } = useError()
	const { hasAcquired: hasAcquiredManageUserAccountWrite } =
		usePermissions(requiredPermissions)

	// Local state
	const [isDiscardOpen, setIsDiscardOpen] = useState(false)

	// APIs
	const [revokeUserAccountAccessMutation] =
		useRevokeUserAccountAccessMutation()

	// Event handlers
	const deactivateClickHandler = useCallback(() => {
		setIsDiscardOpen(true)
	}, [setIsDiscardOpen])

	const editUserClickHandler = useCallback(() => {
		setEditMode()
	}, [setEditMode])

	const modalCloseHandler = useCallback(
		async (option: AssetButtonTypes) => {
			if (option === AssetButtonTypes.CANCEL) {
				setIsDiscardOpen(false)
			} else if (option === AssetButtonTypes.CONFIRM) {
				try {
					const request = await revokeUserAccountAccessMutation({
						variables: {
							request: {
								userId: userData.id
							}
						}
					})

					if (request?.data?.revokeUserAccountAccess?.id) {
						setIsDiscardOpen(false)

						sendNotification(
							NotificationTypes.SNACKBAR,
							{
								snackbarType: SnackbarNotificationTypes.SUCCESS,
								title:
									successfullyRevoked ??
									'[Account successfully revoked]'
							},
							{
								showAfterDelay: 1000
							}
						)

						if (onClose) {
							onClose()
						}
					} else {
						showError()
					}
				} catch (error) {
					showError()
				}
			}
		},
		[
			onClose,
			revokeUserAccountAccessMutation,
			sendNotification,
			showError,
			successfullyRevoked,
			userData.id
		]
	)

	return (
		<>
			<SidebarForm
				footer={
					hasAcquiredManageUserAccountWrite && (
						<FooterWrapper>
							<FooterWrapperLeft>
								<Button.Default
									text={deactivateUser ?? '[Deactivate]'}
									icon={Icon.LeftChevron}
									iconPosition="right"
									buttonType="text"
									colorType="blue"
									onClick={deactivateClickHandler}
								/>
							</FooterWrapperLeft>
							<Button.Default
								text={editUser ?? '[Edit user]'}
								icon={Icon.LeftChevron}
								iconPosition="right"
								buttonType="default"
								colorType="orange"
								onClick={editUserClickHandler}
							/>
						</FooterWrapper>
					)
				}
			>
				<IntroWrapper>
					<Title>{userData.name}</Title>
				</IntroWrapper>
				<DataList>
					<DataListRow
						labelSingle={blok.phoneNumber}
						labelMultiple={blok.phoneNumbers}
						values={userData.phoneNumbers}
					/>
					<DataListRow
						labelSingle={blok.emailAdress}
						labelMultiple={blok.emailaddresses}
						values={userData.emails}
					/>
					{userData?.jobTitle && (
						<DataListRow
							labelSingle={blok.jobTitle}
							labelMultiple={blok.jobTitle}
							values={[userData.jobTitle]}
						/>
					)}
					{userData?.department && (
						<DataListRow
							labelSingle={blok.department}
							labelMultiple={blok.department}
							values={[userData.department]}
						/>
					)}
					{userData?.primaryCompanyName && (
						<DataListRow
							labelSingle={
								blok.labelPrimaryCompany ?? '[Primary Company]'
							}
							labelMultiple={
								blok.labelPrimaryCompany ?? '[Primary Company]'
							}
							values={[userData.primaryCompanyName]}
						/>
					)}
					<DataListRow
						labelSingle={blok.companies}
						labelMultiple={blok.companies}
						values={userData.companies}
					/>
					{userData?.accessRight && (
						<DataListRow
							labelSingle={blok.authorization}
							labelMultiple={blok.authorization}
							values={[userData.accessRight]}
						/>
					)}
					{userData?.status && (
						<DataListRow
							labelSingle={blok.status ?? '[Account status]'}
							labelMultiple={blok.status ?? '[Account status]'}
							values={[userData.status]}
						/>
					)}
					{userData?.identity && (
						<DataListRow
							labelSingle={blok.identity ?? '[Identity]'}
							labelMultiple={blok.identity ?? '[Identity]'}
							values={[userData.identity]}
						/>
					)}

					{userData?.createdDateTimeFormatted && (
						<DataListRow
							labelSingle={
								blok.createdDateTime ?? '[Date created]'
							}
							labelMultiple={
								blok.createdDateTime ?? '[Date created]'
							}
							values={[userData.createdDateTimeFormatted]}
						/>
					)}
					{userData?.lastSignInDateTimeFormatted && (
						<DataListRow
							labelSingle={
								blok.lastSignInDateTime ?? '[Last login date]'
							}
							labelMultiple={
								blok.lastSignInDateTime ?? '[Last login date]'
							}
							values={[userData.lastSignInDateTimeFormatted]}
						/>
					)}
				</DataList>
			</SidebarForm>
			<AlertModal
				body={blok?.deactivateBody}
				isOpen={isDiscardOpen}
				labelCancel={blok?.deactivateDiscard}
				labelConfirm={blok?.deactivateConfirm}
				onClose={modalCloseHandler}
				title={blok?.deactivateTitle}
			/>
		</>
	)
}

export default ActiveForm
