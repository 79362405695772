import styled from 'styled-components'

import { theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { InfoMessageElementProps } from './types'

export const InfoMessageElement = styled.div<InfoMessageElementProps>`
	display: flex;
	flex-direction: row;
	max-width: ${({ fullWidth = false }) => (fullWidth ? '100%' : '512px')};
	margin-top: 20px;
	padding: 24px;
	border-radius: 8px;
	text-align: left;
	background-color: ${theme.colors.blueLight};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	line-height: ${theme.typography.lineHeightTextSmall};
	box-shadow: 10px 20px 40px 0
		${hexToRgb(theme.colors.marineBlack, {
			alpha: 0.1,
			format: 'css'
		})};

	button {
		position: absolute;
		top: 24px;
		right: 24px;
	}
`

export const Inner = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-right: 20px;
	margin-left: 24px;
`

export const Title = styled.span`
	position: relative;
	display: block;
	margin-bottom: 8px;
	font-weight: 500;
`
