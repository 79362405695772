import { CaseStatuses } from '@graphql/graphql'
import React from 'react'

import { DashboardStoryblok } from '@graphql/storyblokcomponents'
import CallToAction from '@components/CallToAction'
import { returnStoryblokComponent } from '@components/Banner/helper'
import ServiceRequestStats from './ServiceRequestStats'
import { DashboardRow } from './styles'
import EmptyState from './EmptyState'

type Props = {
	content: DashboardStoryblok
	iconClickHandler: () => void
}

const callToActionSettings = {
	alignment: 'right',
	verticalAlignment: 'bottom'
}

const Components = {
	callToAction: CallToAction
}

const RenderContent = (blok: any) => {
	if (typeof Components[blok.component] !== 'undefined') {
		return React.createElement(Components[blok.component], {
			key: blok._uid,
			blok,
			callToActionSettings
		})
	}

	return undefined
}

const openStates = Object.values(CaseStatuses).filter(
	(status) =>
		status !== CaseStatuses.Resolved &&
		status !== CaseStatuses.Closed &&
		status !== CaseStatuses.New
)

const ServiceRequestsDashboard = ({ content, iconClickHandler }: Props) => {
	const NewRequestContent = returnStoryblokComponent(
		content.newRequestComponent
	)

	return (
		<DashboardRow>
			<ServiceRequestStats
				key="open"
				title={content.labelOpenRequest}
				seeAll={content.linkTextOpenRequest}
				statuses={openStates}
				iconClickHandler={iconClickHandler}
				errorMessage={content?.serviceRequestsFacetsErrorDescription}
				emptyComponent={
					<EmptyState content={content.labelEmptyOpenRequest} />
				}
			/>
			<ServiceRequestStats
				key="new"
				title={content.labelNewRequest}
				seeAll={content.linkTextNewRequest}
				statuses={[CaseStatuses.Draft, CaseStatuses.New]}
				iconClickHandler={iconClickHandler}
				errorMessage={content?.serviceRequestsFacetsErrorDescription}
				emptyComponent={
					<EmptyState content={content.labelEmptyNewRequest}>
						{NewRequestContent
							? NewRequestContent.map((item) => (
									<React.Fragment key={item._uid}>
										{RenderContent(item)}
									</React.Fragment>
							  ))
							: null}
					</EmptyState>
				}
			/>
		</DashboardRow>
	)
}

export default ServiceRequestsDashboard
