/* eslint-disable react/require-default-props */
import React, { useCallback } from 'react'

import { SortIcon } from '@components/UI/SortIcon'
import { Icon, theme } from '@damen/ui'
import { TableColumn } from '@components/Table/types'
import { GetUserAccountOverviewQuery, UserAccount } from '@graphql/graphql'
import { SortingType } from '@components/GenericOverview/types'
import Status from '@components/UI/Status'
import userAccountColorMapping from '@utils/colors/userAccountColorMapping'
import { UserAccountModalType } from '@components/Modals/UserAccountModal/types'
import {
	TableIconWrapper,
	TableItemTitle
} from '@components/GenericOverview/styles'
import Table from '@components/Table'
import { Mail } from './styles'

import { tableColumnResolver } from './helpers'

export interface UserAccountOverviewTableOrder {
	[key: string]: SortingType
}

export interface UserAccountOverviewDetailPanel {
	open: boolean
	mode: UserAccountModalType
	userAccountId: string
}

interface UserAccountOverviewTableProps {
	items: GetUserAccountOverviewQuery['userAccounts']['items']
	orderBy?: UserAccountOverviewTableOrder
	onOrderByChange?: (orderBy: UserAccountOverviewTableOrder) => void
	nameColumnTitle: string
	emailColumnTitle: string
	statusColumnTitle: string
	createdDateColumnTitle: string
	lastSignInDateColumnTitle: string
	setIsPanelOpen: (panel: UserAccountOverviewDetailPanel) => void
	onIconClick?: (item: TableColumn) => void
}

const UserAccountOverviewTable = ({
	items,
	orderBy,
	onOrderByChange,
	nameColumnTitle,
	emailColumnTitle,
	statusColumnTitle,
	createdDateColumnTitle,
	lastSignInDateColumnTitle,
	setIsPanelOpen,
	onIconClick
}: UserAccountOverviewTableProps) => {
	const openUserDetail = useCallback(
		(user: Pick<UserAccount, 'id'>) => {
			setIsPanelOpen({
				open: true,
				mode: UserAccountModalType.View,
				userAccountId: user.id
			})
		},
		[setIsPanelOpen]
	)

	const sortable = (column: TableColumn) => ({
		...column,
		sortIcon: column.sortIcon ? (
			<SortIcon
				direction={orderBy?.[column.field]}
				text={
					typeof column.title === 'string'
						? column.title
						: column.title.join(' / ')
				}
			/>
		) : null,
		icon: column.icon ? (
			<TableIconWrapper
				onClick={(event) => {
					event.stopPropagation()
					if (onIconClick) {
						onIconClick(column)
					}
				}}
			>
				<Icon.Info fill={theme.colors.grey} height="16" width="16" />
			</TableIconWrapper>
		) : null,
		onClick: () => {
			onOrderByChange({
				[column.field]:
					orderBy?.[column.field] === SortingType.asc
						? SortingType.desc
						: SortingType.asc
			})
		}
	})

	return (
		<Table
			head={{
				columns: [
					sortable({
						title: [nameColumnTitle, emailColumnTitle],
						field: tableColumnResolver.name,
						width: '50%',
						sortIcon: false,
						icon: false
					}),
					sortable({
						title: createdDateColumnTitle,
						field: tableColumnResolver.createdDateTime,
						width: '20%',
						sortIcon: false,
						icon: false
					}),
					sortable({
						title: lastSignInDateColumnTitle,
						field: tableColumnResolver.lastSignInDateTime,
						width: '20%',
						sortIcon: false,
						icon: false
					}),
					sortable({
						title: statusColumnTitle,
						field: tableColumnResolver.status,
						width: '10%',
						sortIcon: false,
						icon: true
					})
				]
			}}
			body={{
				rows: items.map((item) => ({
					id: item.id,
					onClick: () => openUserDetail(item),
					columns: [
						{
							title: (
								<>
									<TableItemTitle>
										{item.name?.fullName}
									</TableItemTitle>
									{item?.emails?.length > 0 ? (
										<Mail>{item.emails[0].email}</Mail>
									) : null}
								</>
							),
							subline: ''
						},
						{
							title: item.createdDateTimeFormatted,
							subline: ''
						},
						{
							title: item.lastSignInDateTimeFormatted,
							subline: ''
						},
						{
							title: (
								<Status
									text={item.status.name}
									state={
										userAccountColorMapping[item.status.id]
									}
								/>
							),
							subline: ''
						}
					]
				}))
			}}
		/>
	)
}

export default UserAccountOverviewTable
