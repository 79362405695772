import React, {
	createContext,
	useState,
	useCallback,
	useContext,
	useMemo
} from 'react'

export const UserAccountStore = createContext(null)

const UserAccountProvider = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)

	const openPanel = useCallback(() => {
		setIsOpen(true)
	}, [setIsOpen])

	const closePanel = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])

	const context = useMemo(
		() => ({
			isOpen,
			openPanel,
			closePanel
		}),
		[closePanel, isOpen, openPanel]
	)

	return (
		<UserAccountStore.Provider value={context}>
			{children}
		</UserAccountStore.Provider>
	)
}

export const useUserAccountContext = () => {
	return useContext(UserAccountStore)
}

export default UserAccountProvider
