import Yup from '@utils/customYupValidations'

import { Validation } from '@components/UI/Forms/Validation/types'
import { ValidationRules } from '@components/UI/Forms/Validation'
import {
	maxEmailMessage,
	maxTextMessage,
	minMessage
} from '@components/UI/Forms/Validation/rules'

export const validationSchema = (messages: Validation) => {
	return Yup.object({
		email: Yup.string()
			.email(messages.validEmail)
			.noWhitespaceAndRequired(messages.required)
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxEmail, maxEmailMessage(messages)),
		companies: Yup.lazy((val) =>
			Array.isArray(val)
				? Yup.array().test(
						'empty-check-companies',
						messages.required,
						(company) =>
							(company as []).filter((item) => item).length > 0
				  )
				: Yup.string().required(messages.required)
		),
		authorization: Yup.lazy((val) =>
			Array.isArray(val)
				? Yup.array()
						.of(
							Yup.object().shape({
								id: Yup.string().required(messages.required),
								name: Yup.string().required(messages.required)
							})
						)
						.test(
							'empty-check-authorization',
							messages.required,
							(company) => !company
						)
				: Yup.string().required(messages.required)
		),
		primaryCompany: Yup.string().required(messages.required),
		lastname: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.max(ValidationRules.maxTextInput, maxTextMessage(messages)),
		firstname: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.max(ValidationRules.maxTextInput, maxTextMessage(messages))
	})
}
