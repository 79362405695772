import { Context, createContext, useContext } from 'react'

interface DefaultStoreProps {
	title?: string
}

const DefaultStore = createContext<DefaultStoreProps>({ title: '' })

const useDynamicTitle = (title: string, store: Context<{ title?: string }>) => {
	const { title: storeTitle } = useContext(store || DefaultStore)

	return storeTitle !== '' && storeTitle !== undefined ? storeTitle : title
}

export default useDynamicTitle
