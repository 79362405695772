import React from 'react'

import { DashboardStoryblok } from '@graphql/storyblokcomponents'
import {
	DashboardCard,
	CardTitle,
	ConnectedAppsContainer,
	CardContent,
	TotalCount
} from './styles'
import ConnectedApp from './ConnectedApp'
import EmptyState from './EmptyState'

type Props = {
	content: DashboardStoryblok
}

const ConnectedApps = ({ content }: Props) => {
	return (
		<DashboardCard>
			<CardTitle>
				{content?.labelConnectedApps}
				{content?.connectedApps?.length > 0 && (
					<TotalCount>{content?.connectedApps?.length}</TotalCount>
				)}
			</CardTitle>
			{content?.connectedApps && content?.connectedApps?.length > 0 && (
				<>
					{content?.contentConnectedApps && (
						<CardContent>
							{content?.contentConnectedApps}
						</CardContent>
					)}
					<ConnectedAppsContainer>
						{content?.connectedApps.map((app) => (
							<ConnectedApp
								key={app._uid}
								title={app.title}
								color={app.color}
								link={app.link}
							/>
						))}
					</ConnectedAppsContainer>
				</>
			)}
			{(!content?.connectedApps ||
				content?.connectedApps?.length <= 0) && (
				<EmptyState content={content.labelEmptyConnectedApps} />
			)}
		</DashboardCard>
	)
}

export default ConnectedApps
