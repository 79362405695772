import { InviteUserAccountRequest } from '@graphql/graphql'

export const mapValues = (values: any): InviteUserAccountRequest => {
	return {
		email: values?.email,
		companies: values?.companies ?? [],
		accessRight: values?.authorization,
		firstname: values?.firstname,
		lastname: values?.lastname,
		primaryCompany: values?.primaryCompany
	} as InviteUserAccountRequest
}
