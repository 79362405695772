import React, { Fragment } from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import MySettingsComponent from '@components/MySettings'
import MySettingsBanner from '@components/Banner/MySettingsBanner'

import PageHead from '@components/PageHead'
import {
	PageItem,
	GetMySettingsPageDataDocument,
	GetMySettingsPageDataQuery
} from '@graphql/graphql'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData = await apolloClient.query<GetMySettingsPageDataQuery>({
		query: GetMySettingsPageDataDocument,
		variables: {
			language: locale
		}
	})

	return pageData?.data?.PageItem ?? {}
}

const Components = {
	header: MySettingsBanner,
	mySettings: MySettingsComponent
}

const MySettings = ({ pageData }: { pageData: PageItem }) => {
	const renderBodyContent = (blok: any) => {
		const validation = pageData?.content?.blocks.find(
			(item) => item.component === 'validation'
		)

		if (typeof Components[blok.component] !== 'undefined') {
			return React.createElement(Components[blok.component], {
				key: blok._uid,
				blok,
				// TODO: Validation should be moved to global object
				validation: validation?.messages[0]
			})
		}

		return undefined
	}

	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="white"
			/>
			{pageData.content.blocks.map((item: any) => (
				<Fragment key={item._uid}>{renderBodyContent(item)}</Fragment>
			))}
		</>
	)
}

export default MySettings
