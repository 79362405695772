import React, { createContext, useMemo, useState } from 'react'
import {
	useGetAccessRightsQuery,
	useGetCompaniesLazyQuery,
	useGetUserAccountQuery
} from '@graphql/graphql'

import { useLocale } from '@hooks/useLocale'
import { DxpFormUserEdit } from '@components/UI/Forms/types'
import { validationSchema } from './Validation'

import { EditUserAccountFormInitialValues } from './types'

export const EditUserAccountStore = createContext<DxpFormUserEdit>(null)

const EditUserAccountProvider = ({ userAccountId, children }) => {
	const [title, setTitle] = useState('')

	const { locale } = useLocale()

	const {
		data: userAccount,
		loading: userAccountLoading,
		error: userAccountError
	} = useGetUserAccountQuery({
		fetchPolicy: 'no-cache',
		variables: {
			language: locale,
			id: userAccountId
		}
	})

	const [loadCompanies] = useGetCompaniesLazyQuery()

	const {
		data: accessRights,
		loading: accessRightsLoading,
		error: accessRightsError
	} = useGetAccessRightsQuery({ variables: { language: locale } })

	const companies = useMemo(() => {
		return (
			userAccount?.userAccount?.companies?.map((company) => company.id) ||
			[]
		)
	}, [userAccount])

	const initialValues: EditUserAccountFormInitialValues = useMemo(() => {
		return {
			userAccountId,
			fullName: userAccount?.userAccount?.name?.fullName || '',
			email: userAccount?.userAccount?.emails[0].email || '',
			companies,
			authorization: userAccount?.userAccount?.accessRight?.id ?? '',
			allAuthorization: accessRights?.accessRights || [],
			primaryCompany:
				userAccount?.userAccount?.primaryCompany?.name ?? 'Unknown'
		}
	}, [
		accessRights?.accessRights,
		companies,
		userAccount?.userAccount?.accessRight?.id,
		userAccount?.userAccount?.emails,
		userAccount?.userAccount?.name?.fullName,
		userAccount?.userAccount?.primaryCompany?.name,
		userAccountId
	])

	const context = useMemo(
		() => ({
			title,
			initialValues,
			setTitle,
			validationSchema,
			loading: userAccountLoading || accessRightsLoading,
			error: userAccountError || accessRightsError,
			loadCompanies
		}),
		[
			title,
			initialValues,
			userAccountLoading,
			accessRightsLoading,
			userAccountError,
			accessRightsError,
			loadCompanies
		]
	)

	return (
		<EditUserAccountStore.Provider value={context}>
			{children}
		</EditUserAccountStore.Provider>
	)
}

export default EditUserAccountProvider
