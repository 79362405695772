import React, { FC } from 'react'

import SkeletonLoader from '@components/UI/SkeletonLoader'

import { CardItem } from '@components/TechnicalPapersItem/styles'

import { CardItemText, CardItemButton } from './styles'
import { TechnicalPapersItemCardProps } from './types'

const TechnicalPapersItem: FC<TechnicalPapersItemCardProps> = ({
	hasBorder = false,
	isLast = false
}) => (
	<CardItem hasBorder={hasBorder} isLast={isLast}>
		<CardItemText>
			<SkeletonLoader height={21} count={3} width="100%" />
		</CardItemText>
		<CardItemButton>
			<SkeletonLoader width={160} height={21} />
		</CardItemButton>
	</CardItem>
)

export default TechnicalPapersItem
