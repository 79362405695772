import React from 'react'

import FormHeader from '@components/UI/Forms/FormHeader'
import { FormFieldHorizontal, FormRow } from '@components/UI/Forms/FormUI'

import FieldSelect from '@components/UI/Forms/FieldSelect'
import FieldRadioGroup from '@components/UI/Forms/FieldRadioGroup'
import { OptionItem } from '@components/UI/Forms/FieldRadioGroup/types'
import { PreferencesProps, PreferencesFormFieldsProps } from './types'

const PreferencesFormFields: React.FC<
	PreferencesProps & PreferencesFormFieldsProps
> = ({
	editPreferencesTitle,
	editPreferencesLanguagePlaceholder,
	labelPreferencesLanguage,
	labelCaseCreatedNotification,
	labelCaseClosedNotification,
	labelCaseCommentCreatedNotification,
	notificationEnableTrueText,
	notificationEnableFalseText,
	languages,
	setFieldValue
}) => {
	const notificationOptionTrue: OptionItem = {
		key: 'true',
		value: notificationEnableTrueText
	}
	const notificationOptionFalse: OptionItem = {
		key: 'false',
		value: notificationEnableFalseText
	}

	return (
		<>
			<FormHeader title={editPreferencesTitle ?? '[Edit preferences]'} />
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldSelect
						name="language"
						label={
							labelPreferencesLanguage ??
							'[Language used in the portal]'
						}
						options={languages}
						placeholder={
							editPreferencesLanguagePlaceholder ?? '[Select]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldRadioGroup
						name="caseCreated"
						label={
							labelCaseCreatedNotification ??
							'[Case Created Notification]'
						}
						options={[
							notificationOptionTrue,
							notificationOptionFalse
						]}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>

			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldRadioGroup
						name="caseClosed"
						label={
							labelCaseClosedNotification ??
							'[Case Closed Notification]'
						}
						options={[
							notificationOptionTrue,
							notificationOptionFalse
						]}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>

			<FormFieldHorizontal>
				<FormRow doubleSpacing>
					<FieldRadioGroup
						name="caseCommentCreated"
						label={
							labelCaseCommentCreatedNotification ??
							'[Comment Created Notification]'
						}
						options={[
							notificationOptionTrue,
							notificationOptionFalse
						]}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default PreferencesFormFields
