import React, { useCallback, useState } from 'react'

import MySettingsModal from '@components/Modals/MySettingsModal'
import ErrorState from '@components/UI/Error'
import { StyledSpacer } from '@components/UI/Forms/FormUI'
import Container from '@components/Container/Container'
import Card from '@components/UI/Card'

import MySettingsSkeleton from '@skeletons/MySettings'
import { useGetMySettingsQuery } from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'
import OverviewSummary from './components/OverviewSummary'

import { MySettingsProps, MySettingForms } from './types'

const MySettings = ({ validation, blok }: MySettingsProps) => {
	// Contexts
	const { locale } = useLocale()

	// Local state
	const [open, setOpen] = useState(false)
	const [formKey, setFormKey] = useState(0)
	const [activeForm, setActiveForm] = useState(null)

	// APIs
	const { data, loading, error } = useGetMySettingsQuery({
		variables: {
			language: locale
		}
	})

	// Event handlers
	const editFormHandler = useCallback(
		(form: MySettingForms) => {
			if (!open) {
				setFormKey((previous) => previous + 1)
				setActiveForm(form)
				setOpen(true)
			}
		},
		[open, setActiveForm, setFormKey, setOpen]
	)

	const submitFormHandler = useCallback(() => {
		setOpen(false)
	}, [setOpen])

	if (error) {
		return (
			<StyledSpacer>
				<Container small>
					<Card>
						<ErrorState
							content={blok?.errorDescription}
							showBowWave
						/>
					</Card>
				</Container>
			</StyledSpacer>
		)
	}

	if (loading) {
		return <MySettingsSkeleton />
	}

	const { myUserAccount } = data ?? {}
	return (
		<>
			<MySettingsModal
				activeForm={activeForm}
				blok={blok}
				formKey={formKey}
				isOpen={open}
				validation={validation}
				myUserAccount={myUserAccount}
				onClose={submitFormHandler}
				onSubmitted={submitFormHandler}
			/>
			<OverviewSummary
				myUserAccount={myUserAccount}
				userAccounts={[]}
				onEdit={editFormHandler}
				{...blok}
			/>
		</>
	)
}

export default MySettings
