import styled from 'styled-components'

export const GraphWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 32px;
	padding: 32px;
	margin: 0 auto;
	max-width: 284px;
`
export const GraphLegendWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 16px;
	margin-bottom: 24px;
`

export const GraphLegendItemWrapper = styled.div`
	height: 21px;
`
