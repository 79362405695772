import { EditUserAccountRequest, UserAccountCompany } from '@graphql/graphql'

export function mapValues(values: any): EditUserAccountRequest {
	const selectedCompanies = values?.companies.map(
		(company: UserAccountCompany | string) =>
			typeof company === 'string' ? company : company.id
	)

	const companies = Array.from(new Set(selectedCompanies))

	return {
		id: values?.userAccountId,
		companies,
		accessRight: values?.authorization
	} as EditUserAccountRequest
}
