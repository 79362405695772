import {
	CardSummaryContainer,
	Wrapper
} from '@components/KnowledgeBaseOverview/styles'
import React from 'react'
import TechnicalPapersItem from '@skeletons/TechnicalPapersItem'
import WebinarItemSkeleton from '@skeletons/WebinarItem'
import KnowledgeBaseCardSkeleton from './Card'

const KnowledgeBaseSkeleton = () => {
	const cards = 3
	const pageId = [0, 1, 2]

	return (
		<Wrapper>
			<KnowledgeBaseCardSkeleton>
				<CardSummaryContainer>
					{[...Array(cards)].map((item, i) => {
						return <TechnicalPapersItem key={pageId[i]} />
					})}
				</CardSummaryContainer>
			</KnowledgeBaseCardSkeleton>
			<KnowledgeBaseCardSkeleton>
				<CardSummaryContainer>
					{[...Array(cards)].map((item, i) => {
						return <WebinarItemSkeleton key={pageId[i]} />
					})}
				</CardSummaryContainer>
			</KnowledgeBaseCardSkeleton>
		</Wrapper>
	)
}

export default KnowledgeBaseSkeleton
