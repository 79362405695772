import React from 'react'
import { CardElement } from './styles'
import { CardProps } from './types'

const Card = ({ children, background, noBottomPadding = false }: CardProps) => (
	<CardElement background={background} noBottomPadding={noBottomPadding}>
		{children}
	</CardElement>
)

export default Card
