import React from 'react'
import Container from '@components/Container/Container'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import {
	FormDescription,
	FormSummaryList,
	FormText,
	FormWrapper,
	StyledSpacer,
	SummaryHeader
} from '@components/UI/Forms/FormUI'
import Card from '@components/UI/Card'
import { FormTitle } from '@components/UI/Forms/FormHeader'

const MySettingsSkeleton: React.FC = () => (
	<StyledSpacer>
		<Container small>
			<Card>
				<FormWrapper>
					<SummaryHeader>
						<div>
							<FormTitle>
								<SkeletonLoader width={193} height={32} />
							</FormTitle>

							<FormDescription>
								<SkeletonLoader width={293} height={24} />
							</FormDescription>
						</div>
						<SkeletonLoader width={60} height={24} />
					</SummaryHeader>
					<div>
						<FormSummaryList>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>

							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>

							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>

							<dt>
								<SkeletonLoader width="40%" height={21} />
							</dt>
							<dd>
								<SkeletonLoader width="50%" height={21} />
							</dd>
							<dt>
								<SkeletonLoader width="40%" height={21} />
							</dt>
							<dd>
								<SkeletonLoader width="50%" height={21} />
							</dd>
						</FormSummaryList>
					</div>
				</FormWrapper>
			</Card>

			<Card>
				<FormWrapper>
					<SummaryHeader>
						<div>
							<FormTitle>
								<SkeletonLoader width={193} height={32} />
							</FormTitle>

							<FormDescription>
								<SkeletonLoader width={293} height={24} />
							</FormDescription>
						</div>
						<SkeletonLoader width={60} height={24} />
					</SummaryHeader>
					<div>
						<FormSummaryList>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="60%" height={21} />
								</dd>
							</>
						</FormSummaryList>
					</div>
				</FormWrapper>
			</Card>

			<Card>
				<FormWrapper>
					<SummaryHeader>
						<FormTitle>
							<SkeletonLoader width={193} height={32} />
						</FormTitle>
					</SummaryHeader>
					<FormText>
						<SkeletonLoader width="100%" height={24} count={2} />
					</FormText>
					<div>
						<FormSummaryList>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader
										width="50%"
										height={21}
										count={3}
									/>
								</dd>
							</>
							<>
								<dt>
									<SkeletonLoader width="40%" height={21} />
								</dt>
								<dd>
									<SkeletonLoader width="80%" height={21} />
								</dd>
							</>
						</FormSummaryList>
					</div>
				</FormWrapper>
			</Card>
		</Container>
	</StyledSpacer>
)

export default MySettingsSkeleton
