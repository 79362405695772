import styled from 'styled-components'

import { theme } from '@damen/ui'

export const Block = styled.span`
	display: block;
`

export const Spacer = styled.span`
	display: inline-block !important;
	width: 12px;
`
export const SupportEmail = styled.a`
	text-decoration: none;
	color: ${theme.colors.blue};
	margin-left: 10px;
`
export const InfoText = styled.span`
	font-style: italic;
	display: inline-block !important;
`
