import React from 'react'

import { Button, Icon } from '@damen/ui'

import {
	Card,
	CardHeader,
	CardHeaderBody,
	CardHeaderTitle,
	CardHeaderText,
	CardHeaderButton,
	CardBody,
	CardSubheading
} from './styles'
import { CardSummaryProps } from './types'

const CardSummary: React.FC<CardSummaryProps> = ({
	title,
	description,
	buttonLabel,
	onButtonClick,
	labelLatest,
	children
}) => (
	<Card>
		<CardHeader>
			<CardHeaderBody>
				<CardHeaderTitle>{title}</CardHeaderTitle>
				<CardHeaderText>{description}</CardHeaderText>
			</CardHeaderBody>
			<CardHeaderButton>
				<Button.Default
					text={buttonLabel}
					colorType="blue"
					icon={Icon.LeftChevron}
					onClick={onButtonClick}
				/>
			</CardHeaderButton>
		</CardHeader>
		<CardBody>
			<CardSubheading>{labelLatest}</CardSubheading>
			{children}
		</CardBody>
	</Card>
)

export default CardSummary
