import React, { useCallback } from 'react'

import { Button, Icon } from '@damen/ui'

import { Container, Image, Body, Title, JobTitle } from './styles'

import { ProfileInfoProps } from './types'

/**
 * @deprecated This is not used anymore. Use the ContactDetails component instead.
 */

const ProfileInfo: React.FC<ProfileInfoProps> = ({
	imageSrc,
	name,
	label,
	email,
	jobTitle
}) => {
	const clickhandler = useCallback(() => {
		window.open(`mailto:${email}`, '_blank')
	}, [email])

	return (
		<Container>
			<Image url={imageSrc} />
			<Body>
				<Title>{name}</Title>
				{jobTitle && <JobTitle>{jobTitle}</JobTitle>}
				<Button.Default
					type="button"
					text={label}
					icon={Icon.LeftChevron}
					colorType="blue"
					buttonType="text"
					onClick={clickhandler}
				/>
			</Body>
		</Container>
	)
}

export default ProfileInfo
