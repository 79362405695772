import { AccessRights } from '@graphql/graphql'

export const isSuperUser = (user: AccessRights) => {
	return [
		AccessRights.SuperuserLevel1,
		AccessRights.SuperuserLevel2,
		AccessRights.SuperuserLevel3,
		AccessRights.SuperuserLevel4
	].includes(user)
}
