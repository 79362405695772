import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@damen/ui'
import { typography, colors, timing } from '@damen/ui/lib/cjs/styles'

export interface SearchFieldProps {
	placeholder?: string
	value?: string
	onChange: (string: string) => void
}

const InputWrapper = styled.div`
	position: relative;
`

export const Input = styled.input`
	height: 48px;
	width: 100%;
	line-height: ${typography.lineHeightTextSmall};
	font-size: ${typography.fontSizeTextSmall}px;
	color: ${colors.marineBlack};
	border: none;
	border-bottom: 1px solid ${colors.marineBlack};
	font-weight: 500;
	transition: border-color ${timing.default} ease-in-out;

	&::placeholder {
		color: ${colors.marineBlack};
		opacity: 1; /* Firefox */
	}

	&:focus {
		outline: none;
		border-bottom: 1px solid ${colors.blue};
	}
`

const IconWrapper = styled.span`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
`

const ClearButton = styled.button`
	padding: 6px;
	background: transparent;
	border: none;
	transition: all 0.2s;
	cursor: pointer;
`

const FormSearchField = ({
	placeholder,
	onChange,
	value = ''
}: SearchFieldProps) => {
	const [searching, setSearching] = useState(value.length > 0)
	const [searchValue, setSearchValue] = useState(value)
	const defaultOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearching(event.target.value.length > 1)
		setSearchValue(event.target.value)

		onChange(event.target.value)
	}

	const clearInput = () => {
		setSearchValue('')
		onChange('')
		setSearching(false)
	}

	return (
		<InputWrapper>
			<Input
				data-testid="SearchFieldInput"
				type="text"
				value={searchValue}
				placeholder={placeholder}
				onChange={defaultOnChange}
			/>
			<IconWrapper data-testid="SearchFieldIcons">
				{searching && (
					<ClearButton type="button" onClick={clearInput}>
						<Icon.Close
							fill={colors.marineBlack}
							height={11}
							width={11}
						/>
					</ClearButton>
				)}

				{!searching && (
					<Icon.Search
						fill={colors.marineBlack}
						height={15}
						width={15}
					/>
				)}
			</IconWrapper>
		</InputWrapper>
	)
}

export default FormSearchField
