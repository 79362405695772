import { UserAccountCompany } from '@graphql/graphql'
import { CompanyOption } from './types'

export function mapCompany(
	item: UserAccountCompany,
	selected: boolean
): CompanyOption {
	return {
		key: item.id,
		value: item.name,
		selected
	}
}
