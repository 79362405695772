import React from 'react'
import { Icon } from '@damen/ui'
import { MY_DAMEN_LIGHT_BLUE } from '@src/constants/Colors'
import { InfoMessageElement, Inner, Title } from './styles'
import { InfoMessageProps } from './types'

const InfoMessage: React.FC<InfoMessageProps> = ({
	className,
	content,
	fullWidth = false,
	onClick,
	title
}) => (
	<InfoMessageElement fullWidth={fullWidth} className={className}>
		<Icon.Info width={32} height={32} fill={MY_DAMEN_LIGHT_BLUE} />
		<Inner>
			{title && <Title>{title}</Title>}
			{content}
		</Inner>
		{onClick && <Icon.CloseThin width={12} height={12} onClick={onClick} />}
	</InfoMessageElement>
)

export default InfoMessage
