import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderBody,
	CardHeaderButton,
	CardHeaderText,
	CardHeaderTitle,
	CardSubheading
} from '@components/KnowledgeBaseOverview/CardSummary/styles'

const KnowledgeBaseCardSkeleton = ({ children }) => {
	return (
		<Card>
			<CardHeader>
				<CardHeaderBody>
					<CardHeaderTitle>
						<SkeletonLoader width={280} height={42} />
					</CardHeaderTitle>
					<CardHeaderText>
						<SkeletonLoader width={500} height={31} />
					</CardHeaderText>
				</CardHeaderBody>
				<CardHeaderButton>
					<SkeletonLoader width={135} height={48} />
				</CardHeaderButton>
			</CardHeader>
			<CardBody>
				<CardSubheading>
					<SkeletonLoader width={135} height={48} />
				</CardSubheading>
				{children}
			</CardBody>
		</Card>
	)
}

export default KnowledgeBaseCardSkeleton
