import React, { FC } from 'react'

import SidebarForm from '@components/SidebarForm'

import { DataListRow } from '../helpers'
import {
	DataList,
	FooterWrapper,
	IntroWrapper,
	InfoMessage,
	Title
} from '../styles'
import { InactiveFormProps } from '../types'

const InactiveForm: FC<InactiveFormProps> = ({ blok, userData }) => {
	// Cached values
	const { inactiveNotification } = blok

	return (
		<SidebarForm footer={<FooterWrapper />}>
			<IntroWrapper>
				<Title>{userData.name}</Title>
			</IntroWrapper>
			<InfoMessage content={inactiveNotification} fullWidth />
			<DataList>
				<DataListRow
					labelSingle={blok.phoneNumber}
					labelMultiple={blok.phoneNumbers}
					values={userData.phoneNumbers}
				/>
				<DataListRow
					labelSingle={blok.emailAdress}
					labelMultiple={blok.emailaddresses}
					values={userData.emails}
				/>
				{userData?.jobTitle && (
					<DataListRow
						labelSingle={blok.jobTitle}
						labelMultiple={blok.jobTitle}
						values={[userData.jobTitle]}
					/>
				)}
				{userData?.department && (
					<DataListRow
						labelSingle={blok.department}
						labelMultiple={blok.department}
						values={[userData.department]}
					/>
				)}
				{userData?.company && (
					<DataListRow
						labelSingle={blok.company}
						labelMultiple={blok.company}
						values={[userData.company]}
					/>
				)}
				<DataListRow
					labelSingle={blok.companies}
					labelMultiple={blok.companies}
					values={userData.companies}
				/>
				{userData?.accessRight && (
					<DataListRow
						labelSingle={blok.authorization}
						labelMultiple={blok.authorization}
						values={[userData.accessRight]}
					/>
				)}
			</DataList>
		</SidebarForm>
	)
}

export default InactiveForm
