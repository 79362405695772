import React from 'react'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import {
	PageItem,
	GetAssetOverviewPageDataQuery,
	GetAssetOverviewPageDataDocument,
	Permissions
} from '@graphql/graphql'

import AssetOverview from '@components/AssetOverview'
import Banner from '@components/Banner'
import PageHead from '@components/PageHead'

import useAuthorization from '@hooks/useAuthorization'
import { BlokZone } from '@components/BlokZone'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData = await apolloClient.query<GetAssetOverviewPageDataQuery>({
		query: GetAssetOverviewPageDataDocument,
		variables: {
			language: locale
		}
	})

	return pageData?.data?.PageItem ?? {}
}

const componentsLookup = {
	header: Banner,
	assetOverview: AssetOverview
}

const AssetOverviewScreen = ({ pageData }: { pageData: PageItem }) => {
	// Hooks
	useAuthorization(Permissions.MyFleetRead)

	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			<BlokZone
				bloks={pageData?.content?.blocks}
				componentLookup={componentsLookup}
			/>
		</>
	)
}

export default AssetOverviewScreen
