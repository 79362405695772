import React from 'react'
import {
	GetKnowledgeBasePageDataDocument,
	PageItem,
	GetKnowledgeBasePageDataQuery
} from '@graphql/graphql'

import { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import PageHead from '@components/PageHead'
import Banner from '@components/Banner'
import KnowledgeBaseOverview from '@components/KnowledgeBaseOverview'
import { BlokZone } from '@components/BlokZone'

export async function GetPageItem(
	apolloClient: ApolloClient<NormalizedCacheObject>,
	locale: string
) {
	const pageData = await apolloClient.query<GetKnowledgeBasePageDataQuery>({
		query: GetKnowledgeBasePageDataDocument,
		variables: {
			language: locale
		}
	})
	return pageData?.data?.PageItem ?? {}
}

const components = {
	header: Banner,
	knowledgeBaseOverview: KnowledgeBaseOverview
}

const KnowledgeBase = ({ pageData }: { pageData: PageItem }) => {
	return (
		<>
			<PageHead
				seoData={pageData?.content?.seoMetatags}
				headerColor="blue"
			/>
			<BlokZone
				bloks={pageData?.content?.blocks}
				componentLookup={components}
			/>
		</>
	)
}

export default KnowledgeBase
