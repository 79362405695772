import React, { createContext, useMemo, useState } from 'react'

import {
	useGetAccessRightsQuery,
	useGetCompaniesLazyQuery
} from '@graphql/graphql'

import { useLocale } from '@hooks/useLocale'

import { DxpFormUserInvite } from '@components/UI/Forms/types'

import { validationSchema } from './Validation'
import { InviteUserAccountFormInitialValues } from './types'

export const InviteUserAccountStore = createContext<DxpFormUserInvite>(null)

const InviteUserAccountProvider = ({ children }) => {
	const [title, setTitle] = useState('')
	const { locale } = useLocale()

	const [loadCompanies] = useGetCompaniesLazyQuery()

	const {
		data: accessRights,
		loading: accessRightsLoading,
		error: accessRightsError
	} = useGetAccessRightsQuery({ variables: { language: locale } })

	const initialValues: InviteUserAccountFormInitialValues = useMemo(() => {
		return {
			email: '',
			firstname: '',
			lastname: '',
			companies: [],
			selectedCompanies: [],
			authorization: accessRights?.accessRights || []
		}
	}, [accessRights?.accessRights])

	const context = useMemo(
		() => ({
			title,
			initialValues,
			setTitle,
			validationSchema,
			loading: accessRightsLoading,
			error: accessRightsError,
			loadCompanies
		}),
		[
			title,
			initialValues,
			accessRightsLoading,
			accessRightsError,
			loadCompanies
		]
	)

	return (
		<InviteUserAccountStore.Provider value={context}>
			{children}
		</InviteUserAccountStore.Provider>
	)
}

export default InviteUserAccountProvider
