import React, { FC, useCallback } from 'react'

import {
	GetUserAccountOverviewDocument,
	useResendUserAccountInvitationMutation,
	Permissions
} from '@graphql/graphql'

import { Button, Icon } from '@damen/ui'

import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import useError from '@hooks/useError'
import SidebarForm from '@components/SidebarForm'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'

import { usePermissions } from '@hooks/usePermission'
import { DataListRow } from '../helpers'
import {
	DataList,
	FooterWrapper,
	IntroWrapper,
	InfoMessage,
	Title
} from '../styles'
import { FormProps } from '../types'

const InviteForm: FC<FormProps> = ({ blok, onClose, userData }) => {
	// Cached values
	const { resentInvitation, inviteNotification, inviteResent } = blok

	// Hooks
	const { showError } = useError()
	const { hasAcquired: hasAcquiredManageUserAccountWrite } = usePermissions(
		Permissions.ManageUserAccountsWrite
	)

	// Contexts
	const { sendNotification } = useNotificationContext()

	// APIs
	const [resendUserAccountInvitationMutation] =
		useResendUserAccountInvitationMutation({
			refetchQueries: [{ query: GetUserAccountOverviewDocument }]
		})

	// Event handlers
	const buttonClickHandler = useCallback(async () => {
		try {
			const request = await resendUserAccountInvitationMutation({
				variables: {
					request: {
						userId: userData.id
					}
				}
			})

			if (request?.data?.resendUserAccountInvitation?.success) {
				sendNotification(
					NotificationTypes.SNACKBAR,
					{
						snackbarType: SnackbarNotificationTypes.SUCCESS,
						title: inviteResent ?? '[The invitation was resent.]'
					},
					{
						showAfterDelay: 1000
					}
				)

				if (onClose) {
					onClose()
				}
			} else {
				showError()
			}
		} catch (error) {
			showError()
		}
	}, [
		resendUserAccountInvitationMutation,
		inviteResent,
		onClose,
		sendNotification,
		showError,
		userData.id
	])

	return (
		<SidebarForm
			footer={
				hasAcquiredManageUserAccountWrite && (
					<FooterWrapper isSmaller>
						<Button.Default
							text={resentInvitation ?? '[Resend invitation]'}
							buttonType="text"
							icon={Icon.LeftChevron}
							iconPosition="right"
							onClick={buttonClickHandler}
						/>
					</FooterWrapper>
				)
			}
		>
			<IntroWrapper>
				<Title>{userData.name}</Title>
			</IntroWrapper>
			<InfoMessage content={inviteNotification} fullWidth />
			<DataList>
				<DataListRow
					labelSingle={blok.phoneNumber}
					labelMultiple={blok.phoneNumbers}
					values={userData.phoneNumbers}
				/>
				<DataListRow
					labelSingle={blok.emailAdress}
					labelMultiple={blok.emailaddresses}
					values={userData.emails}
				/>
				{userData?.accessRight && (
					<DataListRow
						labelSingle={blok.authorization}
						labelMultiple={blok.authorization}
						values={[userData.accessRight]}
					/>
				)}
				<DataListRow
					labelSingle={blok.companies}
					labelMultiple={blok.companies}
					values={userData.companies}
				/>
			</DataList>
		</SidebarForm>
	)
}

export default InviteForm
