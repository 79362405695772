import React, { FC, Fragment } from 'react'
import { Field } from 'formik'

import Radio from '@damen/ui/lib/cjs/components/Form/Radio'

import {
	FormLabel,
	HorizontalWrapper,
	RadioGroupError,
	RadioDescription
} from './styles'
import {
	FieldRadioGroupProps,
	OptionItem,
	OptionWithDescriptionItem,
	WrapperProps
} from './types'

const Wrapper: FC<WrapperProps> = ({ isHorizontal, children }) =>
	isHorizontal ? (
		<HorizontalWrapper>{children}</HorizontalWrapper>
	) : (
		<>{children}</>
	)

const FieldRadioGroup: FC<FieldRadioGroupProps> = ({
	isHorizontal = false,
	hideLabel = false,
	label,
	name,
	options = [],
	setFieldValue
}) => (
	<Field name={name}>
		{({ field, form: { errors } }) => (
			<>
				{label && !hideLabel && (
					<FormLabel htmlFor={name}>{label}</FormLabel>
				)}
				<Wrapper isHorizontal={isHorizontal}>
					{options.map((item: OptionItem | string) => (
						<Fragment
							key={(item as OptionItem)?.key || (item as string)}
						>
							<Radio
								type="radio"
								name={name}
								label={
									(item as OptionItem)?.value ||
									(item as string)
								}
								checked={
									(item as OptionItem)?.key
										? field.value ===
										  (item as OptionItem)?.key
										: field.value === (item as string)
								}
								value={
									(item as OptionItem)?.key ||
									(item as string)
								}
								onChange={() => {
									setFieldValue(
										name,
										(item as OptionItem)?.key ||
											(item as string)
									)
								}}
							/>
							{(item as OptionWithDescriptionItem)
								?.description && (
								<RadioDescription>
									{
										(item as OptionWithDescriptionItem)
											.description
									}
								</RadioDescription>
							)}
						</Fragment>
					))}
				</Wrapper>
				{errors[name] !== '' &&
					errors[name] !== undefined &&
					!hideLabel && (
						<RadioGroupError>{`${label} ${errors[name]}`}</RadioGroupError>
					)}
			</>
		)}
	</Field>
)

export default FieldRadioGroup
