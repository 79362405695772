import React from 'react'
import styled from 'styled-components'

import { theme } from '@damen/ui'
import { MultilinkStoryblok } from '@graphql/storyblokcomponents'
import Link from 'next/link'
import { getStoryblokLinkHref } from '@utils/linkResolver'

const getColor = (color: Color) => {
	switch (color) {
		case 'orange':
			return theme.colors.orange

		case 'blue':
		default:
			return theme.colors.blue
	}
}

const ConnectedAppLink = styled(Link).withConfig({
	shouldForwardProp: (prop) => !['color'].includes(prop)
})<{ color: Color }>`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${theme.layout.spacingM};
	text-align: center;
	width: 120px;
	height: 120px;
	background: ${({ color }) => getColor(color)};
	border-radius: 8px;
	color: ${theme.colors.white};
	font-weight: ${theme.typography.fontWeightBold};

	@media ${theme.legacyMediaQueries.lg} {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
`

type Color = 'blue' | 'orange' | ''

interface Props {
	title: string
	color: Color
	link: MultilinkStoryblok
}

const ConnectedApp = ({ color, title, link }: Props) => {
	const href = getStoryblokLinkHref(link)
	return (
		<ConnectedAppLink key={href} href={href} target="_blank" color={color}>
			{title}
		</ConnectedAppLink>
	)
}

export default ConnectedApp
