import React from 'react'

import useDynamicTitle from '@hooks/useDynamicTitle'

import { Banner as BannerUiComponent } from '@components/UI/Banner'

import { BannerProps } from './types'

const MySettingsBanner = ({
	blok: blokData,
	loading,
	bannerTitleAsync,
	dynamicTitle,
	store
}: BannerProps) => {
	const { title: blockTitle, bannerIcon, smallContainer } = blokData

	const title = useDynamicTitle(blockTitle, store)

	return (
		<BannerUiComponent
			bannerLeft={{
				title: bannerTitleAsync && dynamicTitle ? dynamicTitle : title,
				icon: bannerIcon
			}}
			smallContainer={smallContainer}
			// eslint-disable-next-line react/no-children-prop
			children={null}
			loading={loading}
			bannerTitleAsync={bannerTitleAsync}
		/>
	)
}

export default MySettingsBanner
