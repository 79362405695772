import React from 'react'

import { AssetFilters } from '@components/AssetOverview/types'
import { GetAssetOverviewQuery } from '@graphql/graphql'
import SelectFacets from '@components/SelectFacets'
import ActiveFacets from '@components/ActiveFacets'
import StickyFilters from '@components/StickyFilters'

interface FiltersProps {
	facets: GetAssetOverviewQuery['assets']['facets']
	activeFilters: AssetFilters
	onChangeFilters: (filters: AssetFilters) => void
	vesselTypeFilterLabel: string
	companyNameFilterLabel: string
	noSearchResultsLabel: string
	filterSearchPlaceholder: string
}

const AssetOverviewFilters = ({
	facets: facetsData,
	activeFilters,
	onChangeFilters,
	vesselTypeFilterLabel,
	companyNameFilterLabel,
	noSearchResultsLabel,
	filterSearchPlaceholder
}: FiltersProps) => {
	const facets = [
		{
			key: 'vesselTypes' as const,
			label: vesselTypeFilterLabel,
			options: facetsData?.vesselType || []
		},
		{
			key: 'companyNames' as const,
			label: companyNameFilterLabel,
			search: true,
			options: facetsData?.companyName
		}
	]

	return (
		<StickyFilters>
			<SelectFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
				noSearchResultsLabel={noSearchResultsLabel}
				filterSearchPlaceholder={filterSearchPlaceholder}
			/>

			<ActiveFacets
				facets={facets}
				activeFilters={activeFilters}
				onChangeFilters={onChangeFilters}
			/>
		</StickyFilters>
	)
}

export default AssetOverviewFilters
