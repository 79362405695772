import React, {
	useContext,
	useState,
	useCallback,
	useEffect,
	useMemo
} from 'react'
import { Formik } from 'formik'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import {
	GetUserAccountOverviewDocument,
	InviteStatus,
	InviteUserAccountRequest,
	useGetInviteUserAccountQuery,
	useInviteUserAccountMutation
} from '@graphql/graphql'

import { validationSchema } from '@contexts/InviteUserAccountContext/Validation'
import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import ErrorState from '@components/UI/Error'
import SidebarForm from '@components/SidebarForm'
import FormFooter from '@components/UI/Forms/FormFooter'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import { FormFieldHorizontal, FormRow } from '@components/UI/Forms/FormUI'
import { FormTitle } from '@components/UI/Forms/FormHeader'
import { FormHeaderElement } from '@components/UI/Forms/FormHeader/styles'

import { InviteUserAccountStore } from '@contexts/InviteUserAccountContext'
import { useLocale } from '@hooks/useLocale'
import {
	FormButtonRightCol,
	FormButtonWrapper
} from '@components/UI/Forms/FormFooter/styles'

import useError from '@hooks/useError'

import { InviteUserAccountPanelStoryblok } from '@graphql/storyblokcomponents'
import GeneralFormFields from './components/GeneralFormFields'
import { mapValues } from './helpers/mapValues'

import { InviteUserAccountFormProps } from './types'

const InviteUserAccountForm = ({
	onFormSubmit
}: InviteUserAccountFormProps) => {
	// Contexts
	const {
		initialValues,
		loading: contextLoading,
		error: contextError
	} = useContext(InviteUserAccountStore)
	const { sendNotification } = useNotificationContext()
	const { locale } = useLocale()
	const { showError } = useError()

	// Local state
	const [sending, setSending] = useState(false)
	const [intialized, setInitialized] = useState(false)

	// APIs
	const [inviteUserAccountMutation] = useInviteUserAccountMutation({
		refetchQueries: [{ query: GetUserAccountOverviewDocument }]
	})
	const { data, loading, error } = useGetInviteUserAccountQuery({
		fetchPolicy: 'cache-first',
		variables: {
			language: locale
		}
	})

	const content = data?.GlobalItem?.content
		?.global[0] as InviteUserAccountPanelStoryblok
	const validation = content?.validation[0]?.messages[0]

	// Event handlers
	const initialiseHandler = useCallback(async () => {
		if (!intialized && !loading && content && validation) {
			setInitialized(true)
		}
	}, [content, intialized, loading, setInitialized, validation])

	const getNotificationTitle = useMemo(
		() =>
			(
				formValues: InviteUserAccountRequest,
				invitedStatus: InviteStatus
			) => {
				const inviteMessage = (() => {
					switch (invitedStatus) {
						case InviteStatus.ExistingMyDamenUser:
							return (
								content?.submitSuccessExistingMyDamenUser ??
								'[User already has a registration for MyDamen. Use another email address or send an email to mydamen@damen.com for further information.]'
							)
						case InviteStatus.NewMyDamenUser:
							return (
								content?.submitSuccessNewMyDamenUser ??
								'[The user now has access to MyDamen]'
							)
						default:
							return content?.submitSuccess ?? '[User is invited]'
					}
				})()

				return formValues.email
					? inviteMessage.replace('[VALUE]', formValues.email)
					: inviteMessage
			},
		[
			content?.submitSuccess,
			content?.submitSuccessExistingMyDamenUser,
			content?.submitSuccessNewMyDamenUser
		]
	)

	const submitHandler = useCallback(
		async (values) => {
			setSending(true)

			const formValues = mapValues(values)

			try {
				const request = await inviteUserAccountMutation({
					variables: {
						request: formValues
					}
				})

				if (request.data) {
					const id = request?.data?.inviteUserAccount.id

					if (id) {
						const notificationTitle = getNotificationTitle(
							formValues,
							request?.data?.inviteUserAccount.inviteStatus
						)
						sendNotification(
							NotificationTypes.SNACKBAR,
							{
								snackbarType:
									request?.data?.inviteUserAccount
										.inviteStatus ===
									InviteStatus.ExistingMyDamenUser
										? SnackbarNotificationTypes.WARNING
										: SnackbarNotificationTypes.SUCCESS,
								title: notificationTitle
							},
							{
								showAfterDelay: 2000
							}
						)
					} else {
						showError(
							content?.submitError ||
								'[There was an error. Please try again later]'
						)
					}

					onFormSubmit()
				}
			} catch (requestError) {
				setSending(false)
				showError(
					content?.submitError ||
						'[There was an error. Please try again later]'
				)
				onFormSubmit()
			}
		},
		[
			content?.submitError,
			getNotificationTitle,
			inviteUserAccountMutation,
			onFormSubmit,
			sendNotification,
			showError
		]
	)

	useEffect(() => {
		initialiseHandler()
	}, [initialiseHandler])

	if (error || contextError) {
		return (
			<SidebarForm footer={null}>
				{/* This error is displayed when fteching content from the CMS gives an error, therefore we cannot get a translated error message */}
				<ErrorState
					content="Something went wrong while retrieving the form"
					showBowWave
				/>
			</SidebarForm>
		)
	}

	if (!intialized && (loading || contextLoading)) {
		const companiesArray = [1, 2, 3, 4]
		const authorizationArray = [1, 2]

		return (
			<SidebarForm
				footer={
					<FormButtonWrapper>
						<SkeletonLoader width={66} height={48} />
						<FormButtonRightCol>
							<SkeletonLoader width={143} height={48} />
						</FormButtonRightCol>
					</FormButtonWrapper>
				}
			>
				<FormHeaderElement doubleSpacing={false}>
					<FormTitle>
						<SkeletonLoader width="50%" height={32} />
					</FormTitle>
				</FormHeaderElement>
				<FormFieldHorizontal>
					<FormRow>
						<SkeletonLoader />
						<SkeletonLoader width="100%" height={56} />
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow>
						{companiesArray.map((item) => (
							<span key={item}>
								<SkeletonLoader />
							</span>
						))}
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow>
						{authorizationArray.map((item) => (
							<span key={item}>
								<SkeletonLoader />
							</span>
						))}
					</FormRow>
				</FormFieldHorizontal>
				<FormFieldHorizontal>
					<FormRow>
						<SkeletonLoader width="100%" height={140} />
					</FormRow>
				</FormFieldHorizontal>
			</SidebarForm>
		)
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={submitHandler}
			validationSchema={validationSchema(validation)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ errors, handleSubmit, setFieldValue, values }) => {
				return (
					<SidebarForm
						footer={
							<FormFooter
								labelDiscard={content?.buttonBack}
								labelSaving={content?.stateSaving}
								labelSubmit={content?.buttonSubmit}
								isSending={sending}
								isLastStep
								submitColor="orange"
								onBack={onFormSubmit}
							/>
						}
						onSubmit={handleSubmit}
					>
						<GeneralFormFields
							content={content}
							errors={errors}
							setFieldValue={setFieldValue}
							values={values}
						/>
					</SidebarForm>
				)
			}}
		</Formik>
	)
}

export default InviteUserAccountForm
