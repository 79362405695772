import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import {
	GraphLegendItemWrapper,
	GraphLegendWrapper,
	GraphWrapper
} from './styles'

export const GraphSkeleton = () => {
	return (
		<div>
			<GraphLegendWrapper>
				<GraphLegendItemWrapper>
					<SkeletonLoader width={130} />
				</GraphLegendItemWrapper>
				<GraphLegendItemWrapper>
					<SkeletonLoader width={200} />
				</GraphLegendItemWrapper>
				<GraphLegendItemWrapper>
					<SkeletonLoader width={110} />
				</GraphLegendItemWrapper>
				<GraphLegendItemWrapper>
					<SkeletonLoader width={130} />
				</GraphLegendItemWrapper>
			</GraphLegendWrapper>
			<GraphWrapper>
				<SkeletonLoader height={133} width={32} />
				<SkeletonLoader height={33} width={32} />
				<SkeletonLoader height={200} width={32} />
				<SkeletonLoader height={100} width={32} />
			</GraphWrapper>

			<SkeletonLoader height={24} width={180} />
		</div>
	)
}

export default GraphSkeleton
