const constomerNameReplaceText = '[[name]]'

export const getGreeting = (greetingText: string, userName: string): string => {
	return greetingText.replace(constomerNameReplaceText, userName || '')
}

export const getDashboardBlok = (pageData) => {
	const dashboardData = pageData.content.blocks.find(
		(f) => f.component === 'dashboard'
	)
	return dashboardData
}
