import React, { FC, useCallback } from 'react'

import { Button, Icon } from '@damen/ui'

import useError from '@hooks/useError'

import SidebarForm from '@components/SidebarForm'
import {
	useResendUserAccountInvitationMutation,
	Permissions
} from '@graphql/graphql'
import { NotificationTypes } from '@contexts/NotificationContext/types'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import { useNotificationContext } from '@contexts/NotificationContext'
import { usePermissions } from '@hooks/usePermission'
import { FormProps } from '../types'
import {
	DataList,
	FooterWrapper,
	IntroWrapper,
	InfoMessage,
	Title
} from '../styles'
import { DataListRow } from '../helpers'

const ResentForm: FC<FormProps> = ({ blok, onClose, userData }) => {
	// Hooks
	const { showError } = useError()
	const { hasAcquired: hasAcquiredManageUserAccountWrite } = usePermissions(
		Permissions.ManageUserAccountsWrite
	)

	// Contexts
	const { sendNotification } = useNotificationContext()

	// APIs
	const [resentUserAccountInvitationMutation] =
		useResendUserAccountInvitationMutation()

	// Cached values
	const { inviteExpiredNotification, sendInvitationAgain } = blok

	// Event handlers
	const buttonClickHandler = useCallback(async () => {
		try {
			const request = await resentUserAccountInvitationMutation({
				variables: {
					request: {
						userId: userData.id
					}
				}
			})

			if (request?.data?.resendUserAccountInvitation?.success) {
				sendNotification(
					NotificationTypes.SNACKBAR,
					{
						snackbarType: SnackbarNotificationTypes.SUCCESS,
						title: '[Invitation was resent. Please check your email]'
					},
					{
						showAfterDelay: 1000
					}
				)

				if (onClose) {
					onClose()
				}
			} else {
				showError()
			}
		} catch (error) {
			showError()
		}
	}, [
		resentUserAccountInvitationMutation,
		userData.id,
		sendNotification,
		onClose,
		showError
	])

	return (
		<SidebarForm
			footer={
				hasAcquiredManageUserAccountWrite && (
					<FooterWrapper isSmaller>
						<Button.Default
							text={
								sendInvitationAgain ?? '[Send invitation again]'
							}
							buttonType="text"
							icon={Icon.LeftChevron}
							iconPosition="right"
							onClick={buttonClickHandler}
						/>
					</FooterWrapper>
				)
			}
		>
			<IntroWrapper>
				<Title>{userData.name}</Title>
			</IntroWrapper>
			<InfoMessage content={inviteExpiredNotification} fullWidth />
			<DataList>
				<DataListRow
					labelSingle={blok.phoneNumber}
					labelMultiple={blok.phoneNumbers}
					values={userData.phoneNumbers}
				/>
				<DataListRow
					labelSingle={blok.emailAdress}
					labelMultiple={blok.emailaddresses}
					values={userData.emails}
				/>
				{userData?.jobTitle && (
					<DataListRow
						labelSingle={blok.jobTitle}
						labelMultiple={blok.jobTitle}
						values={[userData.jobTitle]}
					/>
				)}
				{userData?.department && (
					<DataListRow
						labelSingle={blok.department}
						labelMultiple={blok.department}
						values={[userData.department]}
					/>
				)}
				{userData?.company && (
					<DataListRow
						labelSingle={blok.company}
						labelMultiple={blok.company}
						values={[userData.company]}
					/>
				)}
				<DataListRow
					labelSingle={blok.companies}
					labelMultiple={blok.companies}
					values={userData.companies}
				/>
				{userData?.accessRight && (
					<DataListRow
						labelSingle={blok.authorization}
						labelMultiple={blok.authorization}
						values={[userData.accessRight]}
					/>
				)}
			</DataList>
		</SidebarForm>
	)
}

export default ResentForm
