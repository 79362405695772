import {
	EditMyUserAccountRequest,
	EmailTypes,
	PhonenumberTypes
} from '@graphql/graphql'

export const mapValues = (values: any): EditMyUserAccountRequest => {
	const result: EditMyUserAccountRequest = {
		name: {
			firstName: values?.firstName,
			middleName: values?.middleName ?? '',
			lastName: values?.lastName,
			title: values?.title
		},
		emails: [
			{
				email: values?.email,
				primary: true,
				type: EmailTypes.Home
			}
		],
		phoneNumbers: [
			{
				phonenumber: values?.phone,
				primary: true,
				type: PhonenumberTypes.Home
			},
			values.mobile
				? {
						phonenumber: values?.mobile,
						primary: true,
						type: PhonenumberTypes.Mobile
				  }
				: undefined
		].filter((x) => x !== undefined),
		work: {
			company: values?.company,
			department: values?.department,
			jobTitle: values?.jobTitle
		}
	}
	return result
}
