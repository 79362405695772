import styled, { css } from 'styled-components'

import { theme } from '@damen/ui'

import InfoMessageComponent from '@components/UI/InfoMessage'

import { FooterWrapperProps } from './types'

export const IntroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 0;
	flex-shrink: 0;
`

export const FooterWrapper = styled.div<FooterWrapperProps>`
	display: flex;
	justify-content: flex-end;
	width: 100%;

	button {
		margin-top: auto;
		margin-bottom: auto;

		${({ isSmaller }) =>
			isSmaller &&
			css`
				font-size: 14px;
				line-height: 1em;
			`}
	}
`

export const FooterWrapperLeft = styled.div`
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;

	> button {
		margin-right: 32px;
		font-size: 14px;
		line-height: 1em;
	}
`

export const Title = styled.h2`
	font-size: ${theme.typography.fontSizeHeadingFour}px;
	line-height: ${theme.typography.lineHeightHeadingFour};
	margin-top: 0;
	margin-bottom: 56px;
	color: ${theme.colors.marineBlack};
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 100%;
`

export const InfoMessage = styled(InfoMessageComponent)`
	margin: 0 0 24px;
	box-shadow: none;
`

export const InfoMessageWrapper = styled.div`
	margin-bottom: 24px;
`

export const DataList = styled.dl`
	color: ${theme.colors.marineBlack};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	line-height: ${theme.typography.lineHeightTextLarge};
	display: flex;
	flex-flow: row wrap;
	width: 100%;

	dt {
		width: 40%;
		min-width: 96px;
		color: ${theme.colors.grey};
		padding: 0 6px;
	}

	dd {
		width: 60%;
		color: ${theme.colors.marineBlack};
		padding: 0 6px;
	}

	@media ${theme.legacyMediaQueries.md} {
		dt {
			padding-right: 24px;
		}

		dd {
			padding-left: 24px;
		}
	}
`

export const ErrorStateWrapper = styled.div`
	padding: 10px 32px 20px;

	@media ${theme.legacyMediaQueries.md} {
		padding: 20px 64px 40px;
	}
`
