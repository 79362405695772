import { GetStaticProps, GetStaticPaths } from 'next'
import React, { useEffect } from 'react'
import { initializeApollo, addApolloState } from '@lib/apollo'
import { getIsrRevalidate } from '@utils/getIsrRevalidate'

import { useHeaderContext } from '@contexts/HeaderContext'

import ServiceRequests, {
	GetPageItem as getServiceRequestPageItem
} from '@screens/ServiceRequests/ServiceRequestOverview'
import AssetOverviewScreen, {
	GetPageItem as getAssetOverviewPageItem
} from '@screens/Assets/AssetOverview'
import UserAccountOverview, {
	GetPageItem as getUserAccountOverviewPageItem
} from '@screens/UserAccounts/UserAccountOverview'
import KnowledgeBase, {
	GetPageItem as getKnowledgeBasePageItem
} from '@screens/KnowledgeBase/KnowledgeBase'
import PartRequest, {
	GetPageItem as getPartRequestItem
} from '@screens/PartRequest/PartRequestOverview'
import MySettings, {
	GetPageItem as getMySettingsPageItem
} from '@screens/MySettings'
import Dashboard, { GetPageItem as getDashboardData } from '@screens/Dashboard'
import Content, { GetPageItem as getContentData } from '@screens/Content'

import { PageLookups } from '@src/types/pages/types'
import UserAccountProvider from '@contexts/UserAccountContext'
import { getDefaultPageData } from '@screens/default'
import { getPublicRuntimeConfig } from '@utils/getPublicRuntimeConfig'

const PageLookup: PageLookups = {
	'service-requests': {
		getPageItem: getServiceRequestPageItem,
		screen: ServiceRequests
	},
	'my-fleet': {
		getPageItem: getAssetOverviewPageItem,
		screen: (props) => <AssetOverviewScreen {...props} />
	},
	'my-settings': {
		getPageItem: getMySettingsPageItem,
		screen: MySettings
	},
	'user-accounts': {
		getPageItem: getUserAccountOverviewPageItem,
		screen: (props) => (
			<UserAccountProvider>
				<UserAccountOverview {...props} />
			</UserAccountProvider>
		)
	},
	'knowledge-base': {
		getPageItem: getKnowledgeBasePageItem,
		screen: KnowledgeBase
	},
	'part-requests': {
		getPageItem: getPartRequestItem,
		screen: PartRequest
	},

	dashboard: {
		slugOverride: '',
		getPageItem: getDashboardData,
		screen: Dashboard
	},
	'': {
		slugOverride: '',
		getPageItem: getDashboardData,
		screen: Dashboard
	}
}

export const getStaticPaths: GetStaticPaths = async () => {
	return {
		paths: [],
		fallback: 'blocking'
	}
}

export const getStaticProps: GetStaticProps = async (context) => {
	const { slugs } = context.params
	const { locale } = context

	const slugPath = Array.isArray(slugs)
		? slugs.join('/').toLowerCase()
		: slugs?.toLowerCase()

	const apolloClient = initializeApollo()
	const page = PageLookup[slugPath as string]

	// Is non-default content route
	if (page) {
		const pageItem = page.getPageItem
			? await page.getPageItem(apolloClient, locale)
			: undefined

		if (!pageItem) {
			return {
				notFound: true
			}
		}

		await getDefaultPageData(apolloClient, locale)
		return addApolloState(apolloClient, {
			props: {
				slug: page.slugOverride ?? slugPath,
				pageData: pageItem,
				...getPublicRuntimeConfig()
			},
			revalidate: getIsrRevalidate(pageItem.content)
		})
	}

	// Is Content Page
	const pageItem = await getContentData(apolloClient, locale, slugPath)
	if (!pageItem || !pageItem.content) {
		return {
			notFound: true,
			props: {
				...getPublicRuntimeConfig()
			}
		}
	}

	await getDefaultPageData(apolloClient, locale)
	return addApolloState(apolloClient, {
		props: {
			slug: page?.slugOverride ?? slugPath,
			pageData: pageItem,
			...getPublicRuntimeConfig()
		},
		revalidate: getIsrRevalidate(pageItem.content)
	})
}

const Page = ({ slug, pageData }) => {
	const { setParentLink } = useHeaderContext()

	useEffect(() => {
		setParentLink(pageData?.parentLink || null)
	}, [pageData?.parentLink, setParentLink])

	const page = PageLookup[slug]
	const Component = page?.screen ?? Content
	return <Component pageData={pageData} />
}

export default Page
