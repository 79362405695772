import React, { createContext, useMemo } from 'react'
import { DxpForm } from '@components/UI/Forms/types'

import { validationSchema } from './Validation'
import { initialValues } from './initialValues'

export const MySettingsPreferencesStore = createContext<DxpForm>(null)

const MySettingsPreferencesProvider = ({ children }) => {
	const context = useMemo(
		() => ({
			initialValues,
			validationSchema
		}),
		[]
	)

	return (
		<MySettingsPreferencesStore.Provider value={context}>
			{children}
		</MySettingsPreferencesStore.Provider>
	)
}

export default MySettingsPreferencesProvider
