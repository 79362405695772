import * as Yup from 'yup'

import { Validation } from '@components/UI/Forms/Validation/types'

export const validationSchema = (messages: Validation) => {
	return Yup.object({
		authorization: Yup.lazy((val) =>
			Array.isArray(val)
				? Yup.array()
						.of(
							Yup.object().shape({
								id: Yup.string().required(messages.required),
								name: Yup.string().required(messages.required)
							})
						)
						.test(
							'empty-check-authorization',
							messages.required,
							(company) => !company
						)
				: Yup.string()
						.required(messages.required)
						.test(
							'not-none',
							messages.required,
							(value) => value.toLowerCase() !== 'none'
						)
		)
	})
}
