import React, { FC, useMemo } from 'react'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import {
	AccessRights,
	Permissions,
	useGetUserAccountPageDataQuery,
	useGetUserAccountQuery
} from '@graphql/graphql'

import SidebarForm from '@components/SidebarForm'
import ErrorState from '@components/UI/Error'

import { useLocale } from '@hooks/useLocale'

import {
	ACTIVE,
	BLOCKED,
	GRACE,
	INACTIVE,
	INVITED,
	WITHDRAWN
} from '@src/constants/Statuses'

import { isSuperUser } from '@utils/isSuperUser'
import { usePermissions } from '@hooks/usePermission'
import { UserAccountDetailStoryblok } from '@graphql/storyblokcomponents'
import ActiveForm from './components/ActiveForm'
import InviteForm from './components/InviteForm'
import InactiveForm from './components/InactivateForm'
import ResentForm from './components/ResentForm'
import BlockedForm from './components/BlockedForm'
import NoAccessForm from './components/NoAccessForm'

import { mapContent } from './helpers'
import {
	DataList,
	ErrorStateWrapper,
	FooterWrapper,
	InfoMessageWrapper,
	IntroWrapper,
	Title
} from './styles'
import { ViewUserAccountProps } from './types'

const ViewUserAccount: FC<ViewUserAccountProps> = ({
	onClose,
	setEditMode,
	setReactivateMode,
	userAccountId
}) => {
	// Contexts
	const { locale } = useLocale()

	// APIs
	const {
		data: pageData,
		loading: loadingPageData,
		error: errorPageData
	} = useGetUserAccountPageDataQuery({
		variables: {
			language: locale
		}
	})

	const {
		data: userAccountData,
		loading,
		error: errorUserAccountData
	} = useGetUserAccountQuery({
		fetchPolicy: 'no-cache',
		variables: {
			id: userAccountId,
			language: locale
		}
	})

	const requiredPermissions = isSuperUser(
		userAccountData?.userAccount?.accessRight?.id
	)
		? Permissions.ManageSuperUserAccountsWrite
		: Permissions.ManageUserAccountsWrite

	const { hasAcquired: hasAcquiredManageUserAccountWrite } =
		usePermissions(requiredPermissions)

	// Cached values
	const blok = useMemo(
		() => mapContent(pageData?.GlobalItem?.content?.global[0]),
		[pageData]
	) as UserAccountDetailStoryblok

	const userData = useMemo(() => {
		return userAccountData
			? {
					id: userAccountData?.userAccount?.id,
					name:
						userAccountData?.userAccount?.name?.fullName === ' '
							? userAccountData?.userAccount?.emails[0]?.email
							: userAccountData?.userAccount?.name?.fullName,
					phoneNumbers:
						userAccountData?.userAccount?.phoneNumbers?.map(
							(phone) => `${phone.phonenumber}`
						),
					emails: userAccountData?.userAccount?.emails?.map(
						(email) => `${email.email}`
					),
					company: userAccountData?.userAccount?.work?.company,
					companies: userAccountData?.userAccount?.companies?.map(
						(item) => item.name
					),
					status: userAccountData?.userAccount?.status?.id,
					accessRight:
						userAccountData?.userAccount?.accessRight?.name,
					jobTitle: userAccountData?.userAccount?.work?.jobTitle,
					department: userAccountData?.userAccount?.work?.department,
					identity: userAccountData?.userAccount.identity,
					createdDateTimeFormatted:
						userAccountData?.userAccount.createdDateTimeFormatted,
					lastSignInDateTimeFormatted:
						userAccountData?.userAccount
							.lastSignInDateTimeFormatted,
					primaryCompanyName:
						userAccountData.userAccount.primaryCompany?.name ??
						'Unknown'
			  }
			: null
	}, [userAccountData])

	if (errorPageData) {
		return (
			<SidebarForm footer={null}>
				{/* This error is displayed when fetching content from the CMS gives an error, therefore we cannot get a translated error message */}
				<ErrorState
					content="Something went wrong while retrieving the content"
					showBowWave
				/>
			</SidebarForm>
		)
	}

	if (loading || loadingPageData) {
		return (
			<SidebarForm
				footer={
					hasAcquiredManageUserAccountWrite && (
						<FooterWrapper>
							<SkeletonLoader width={90} height={48} />
						</FooterWrapper>
					)
				}
			>
				<IntroWrapper>
					<Title>
						<SkeletonLoader height={42} width={200} />
					</Title>
				</IntroWrapper>
				<InfoMessageWrapper>
					<SkeletonLoader width="100%" height={96} />
				</InfoMessageWrapper>
				<DataList>
					<dt>
						<SkeletonLoader height={24} width="100%" />
					</dt>
					<dd>
						<SkeletonLoader height={24} width="100%" />
					</dd>
					<dt>
						<SkeletonLoader height={24} width="100%" />
					</dt>
					<dd>
						<SkeletonLoader height={24} width="100%" />
					</dd>
					<dt>
						<SkeletonLoader height={24} width="100%" />
					</dt>
					<dd>
						<SkeletonLoader height={24} width="100%" />
					</dd>
					<dt>
						<SkeletonLoader height={24} width="100%" />
					</dt>
					<dd>
						<SkeletonLoader height={24} width="100%" />
					</dd>
				</DataList>
			</SidebarForm>
		)
	}

	if (userData === null || errorUserAccountData) {
		return (
			<ErrorStateWrapper>
				<ErrorState content={blok?.errorDescription} showBowWave />
			</ErrorStateWrapper>
		)
	}

	const status = userData?.status?.toLowerCase()

	if (status === INVITED) {
		return <InviteForm blok={blok} onClose={onClose} userData={userData} />
	}

	const accessRight = userAccountData?.userAccount?.accessRight
	if (
		status === ACTIVE &&
		(!accessRight || accessRight.id === AccessRights.None)
	) {
		return (
			<NoAccessForm
				blok={blok}
				userData={userData}
				setReactivateMode={setReactivateMode}
			/>
		)
	}

	if (status === ACTIVE || status === GRACE) {
		return (
			<ActiveForm
				blok={blok}
				onClose={onClose}
				setEditMode={setEditMode}
				userData={userData}
				requiredPermissions={requiredPermissions}
			/>
		)
	}

	if (status === INACTIVE) {
		return <InactiveForm blok={blok} userData={userData} />
	}

	if (status === WITHDRAWN) {
		return <ResentForm blok={blok} onClose={onClose} userData={userData} />
	}

	if (status === BLOCKED) {
		return <BlockedForm blok={blok} onClose={onClose} userData={userData} />
	}

	return <></>
}

export default ViewUserAccount
