import styled from 'styled-components'

export const CardItemText = styled.p`
	margin: 0 0 24px;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
`
export const CardItemButton = styled.span`
	font-size: 14px;
`
