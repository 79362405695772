import React, { useCallback } from 'react'

import {
	AssetOrder,
	GetAssetOverviewQuery,
	GetAssetOverviewQueryVariables,
	useGetAssetOverviewLazyQuery
} from '@graphql/graphql'
import { SearchField } from '@damen/ui'
import { useLocale } from '@hooks/useLocale'
import GenericOverview from '@components/GenericOverview'
import { SortingType } from '@components/GenericOverview/types'
import TwoColRow from '@components/TwoColRow'
import { DefaultGenericError } from '@components/GenericOverview/components/GenericError/DefaultGenericError'
import GenericOverviewSkeleton from '@skeletons/GenericOverview'
import Spacer from '@components/Spacer'
import { AssetColumnKeys, AssetFilters, AssetOverviewProps } from './types'
import AssetOverviewFilters from './AssetOverviewFilters'
import AssetOverviewTable from './AssetOverviewTable'
import { OverviewWrapper } from './styles'

const AssetOverview = ({ blok }: AssetOverviewProps) => {
	const { locale } = useLocale()
	const [load] = useGetAssetOverviewLazyQuery()

	const pageSize = 20

	return (
		<OverviewWrapper>
			<GenericOverview<
				GetAssetOverviewQueryVariables,
				GetAssetOverviewQuery,
				AssetFilters
			>
				loadingText={blok.loadingContent}
				loadType="infinite"
				loadData={useCallback(
					({ filters, searchQuery, orderBy, page }) =>
						load({
							variables: {
								language: locale,
								vesselTypes: filters.vesselTypes,
								companyNames: filters.companyNames ?? '',
								searchText: searchQuery,
								skip: (page - 1) * pageSize,
								limit: pageSize,
								order: [
									(!orderBy ||
										orderBy[AssetColumnKeys.vessel] ===
											SortingType.asc) &&
										AssetOrder.NameAsc,
									orderBy?.[AssetColumnKeys.vessel] ===
										SortingType.desc && AssetOrder.NameDesc,
									orderBy?.[
										AssetColumnKeys.deliveryDateFormatted
									] === SortingType.asc &&
										AssetOrder.DeliveryDateAsc,
									orderBy?.[
										AssetColumnKeys.deliveryDateFormatted
									] === SortingType.desc &&
										AssetOrder.DeliveryDateDesc
								].filter(Boolean)
							}
						}),
					[load, locale]
				)}
				mergeData={(prev, next) => ({
					...prev,
					assets: {
						...prev.assets,
						items: [...prev.assets.items, ...next.assets.items]
					}
				})}
				getTotalPages={(data) => data.assets.total / pageSize}
				renderHeader={({
					data,
					filters,
					onChangeFilters,
					searchQuery,
					setSearchQuery
				}) => (
					<>
						<TwoColRow
							mobileLayout="columnReverse"
							spacerBorderBottom={false}
							paddingBottom={false}
							left={
								<SearchField
									value={searchQuery}
									onChange={setSearchQuery}
									placeholder={blok.searchPlaceholder}
								/>
							}
						/>
						<Spacer paddingBottom={false}>
							<AssetOverviewFilters
								facets={data.assets.facets}
								activeFilters={filters}
								vesselTypeFilterLabel={blok.filterType}
								companyNameFilterLabel={blok.filterCompanyName}
								onChangeFilters={onChangeFilters}
								noSearchResultsLabel={blok.searchNoResultsFound}
								filterSearchPlaceholder={blok.filterSearchLabel}
							/>
						</Spacer>
					</>
				)}
				renderTable={({ data, searchWord, orderBy, setOrderBy }) => (
					<AssetOverviewTable
						items={data.assets.items}
						searchWord={searchWord}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						nameColumnTitle={blok.tableVessel}
						yardNumberColumnTitle={blok.tableYardNumber}
						typeColumnTitle={blok.tableType}
						deliveryDateColumnTitle={blok.tableDeliveryDate}
						favouriteColumnTitle={blok.tableFavourite}
					/>
				)}
				renderError={({ type, searchQuery }) => (
					<DefaultGenericError
						type={type}
						searchQuery={searchQuery}
						translations={blok}
					/>
				)}
				skeleton={<GenericOverviewSkeleton filterAmount={3} />}
			/>
		</OverviewWrapper>
	)
}

export default AssetOverview
