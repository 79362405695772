import {
	CaseOrder,
	useGetServiceRequestOverviewCasesQuery,
	useGetServiceRequestPageDataQuery
} from '@graphql/graphql'
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Icon } from '@damen/ui'

import { useLocale } from '@hooks/useLocale'

import { DashboardStoryblok } from '@graphql/storyblokcomponents'
import TableSkeleton from '@skeletons/Table'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import ServiceRequestOverviewTable from '@components/ServiceRequestOverview/ServiceRequestOverviewTable'
import ErrorState from '@components/UI/Error'
import { SERVICEREQUESTS } from '@src/constants/Routes'
import {
	TotalCount,
	DashboardCard,
	ServiceRequestStatsTitle,
	ServiceRequestsRowFullWidth,
	StyledSeeAllButton
} from './styles'
import EmptyState from './EmptyState'

type Props = {
	content: DashboardStoryblok
	iconClickHandler: () => void
}

const ServiceRequestsLatestRequests = ({
	content,
	iconClickHandler
}: Props) => {
	const { locale } = useLocale()
	// No error handling as the translations fallback is implemented
	const { data: serviceRequestData, loading: tableTranslationsLoading } =
		useGetServiceRequestPageDataQuery({
			variables: {
				language: locale
			},
			fetchPolicy: 'cache-only'
		})

	const translations = serviceRequestData?.PageItem?.content?.blocks.filter(
		(blok) => blok.component === 'serviceRequestOverview'
	)?.[0]

	const {
		data: caseData,
		loading: casesLoading,
		error: casesError
	} = useGetServiceRequestOverviewCasesQuery({
		variables: {
			language: locale,
			limit: 5,
			order: CaseOrder.CreatedAtDesc
		}
	})

	const router = useRouter()
	const serviceRequestsClickHandler = useCallback(() => {
		router.push({
			pathname: SERVICEREQUESTS
		})
	}, [router])

	if (casesLoading || tableTranslationsLoading) {
		return (
			<ServiceRequestsRowFullWidth>
				<DashboardCard>
					<ServiceRequestStatsTitle>
						<SkeletonLoader width={200} height={56} />
					</ServiceRequestStatsTitle>
					<TableSkeleton rows={5} />
				</DashboardCard>
			</ServiceRequestsRowFullWidth>
		)
	}

	if (casesError) {
		return (
			<ServiceRequestsRowFullWidth>
				<DashboardCard>
					{content.labelLatestRequests && (
						<ServiceRequestStatsTitle>
							{content.labelLatestRequests}
						</ServiceRequestStatsTitle>
					)}
					<ErrorState
						content={
							content?.latestServiceRequestsErrorDescription ??
							'Something went wrong while retrieving the latest service requests'
						}
						showBowWave
					/>
				</DashboardCard>
			</ServiceRequestsRowFullWidth>
		)
	}

	return (
		<ServiceRequestsRowFullWidth>
			<DashboardCard>
				<ServiceRequestStatsTitle>
					{content.labelLatestRequests}
					{caseData?.cases?.items?.length > 0 && (
						<TotalCount>
							{caseData?.cases?.items?.length}
						</TotalCount>
					)}
				</ServiceRequestStatsTitle>
				{caseData && caseData?.cases?.items?.length > 0 && (
					<>
						<ServiceRequestOverviewTable
							items={caseData.cases.items}
							numberColumnTitle={translations?.tableNumber}
							descriptionColumnTitle={
								translations?.tableDescription?.split('/')[0] ??
								'[Subject]'
							}
							systemColumnTitle={
								translations?.tableDescription?.split('/')[1]
							}
							categoryColumnTitle={
								translations?.tableCategory ?? '[Category]'
							}
							dateColumnTitle={
								translations?.tableDate ?? '[Date]'
							}
							requestedByColumnTitle={
								translations?.tableRequestedBy ??
								'[Requested by]'
							}
							statusColumnTitle={
								translations?.tableStatus ?? '[Status]'
							}
							onIconClick={iconClickHandler}
						/>
						<StyledSeeAllButton
							text={content.linkTextAllRequests}
							onClick={serviceRequestsClickHandler}
							buttonType="text"
							colorType="blue"
							icon={Icon.LeftChevron}
							iconPosition="right"
							iconSize={14}
						/>
					</>
				)}
				{(!caseData || caseData?.cases?.items?.length <= 0) && (
					<EmptyState content={content.labelEmptyLatestRequests} />
				)}
			</DashboardCard>
		</ServiceRequestsRowFullWidth>
	)
}

export default ServiceRequestsLatestRequests
